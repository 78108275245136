@use "../../sass/variables"as *;
@use "../../sass/mixins"as *;

$object: "promo-banner";

.#{$object} {
    padding: rem-calc(0) rem-calc($gutter-mobile);

    &__inner {
        padding: rem-calc(40px) rem-calc(0) rem-calc(30px);
    }

    &__heading {
        margin: rem-calc(0);
        font-size: rem-calc(32px);
        line-height: rem-calc(30px);
        color: $color-primary;        
        font-weight: $font-weight-bold;
    }

    &__cta {
        margin: rem-calc(40px) rem-calc(0) rem-calc(0) rem-calc(0);
    }

    &__image {
        display: none;
    }

    &__description p {
        color: $color-medium;
    }

    &__description.theme-tertiary p {
        color: $color-white;
    }

    &__heading.theme-tertiary p {
        color: $color-white;
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        padding: rem-calc(0) rem-calc($gutter-desktop);

        &__heading {
            font-size: rem-calc(42px);
            line-height: rem-calc(40px); 
        }

        &__inner {
            display: grid;
            grid-template-columns: 1fr 1fr;
            margin: rem-calc(0) auto;
            max-width: rem-calc($width-max);
            min-height: rem-calc(225px);
            padding: rem-calc(0);
        }

        &__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 1;
            padding: rem-calc(20px) rem-calc(20px) rem-calc(20px) rem-calc(0px);
        }

        &__right {
            position: relative;
            display: flex;
            justify-content: center;
            margin: rem-calc(0);
        }

        &__heading {
            max-width: rem-calc(574px);

            &+.#{$object}__description {
                margin: rem-calc(10px) rem-calc(0) rem-calc(0) rem-calc(0);
            }
        }

        &__description {
            max-width: rem-calc(642px);
        }

        &__description p {
            margin: rem-calc(0);
        }

        &__cta {
            position: relative;
            z-index: 1;
            align-self: center;
            margin: rem-calc(0);
        }

        &__image {
            display: inline;
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            max-height: 100%;
        }
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        &__right {
            justify-content: initial;
        }

        &__cta {
            margin: rem-calc(0) rem-calc(0) rem-calc(0) rem-calc(20px);
        }
    }

    // Targeting iPads or tablets, when in vertical / portrait mode
    // Note: image will also not display when in Chrome's device debug tool and device height is more than its width
    @media (pointer: coarse) and (orientation: portrait) {
        &__image {
            display: none;
        }
    }
}

.#{$object}.theme-tertiary{
    .#{$object}__description a{
        color: $color-white;
        // text-decoration: none;
        font-weight: normal;
        &:hover{
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }      
    }
  }