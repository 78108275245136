$object: "redirect";

.#{$object} {
    &--preview-mode {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: rem-calc(20px) rem-calc(0);

        &__header {
            text-align: center;

            &__label {
                margin-top: rem-calc(0);
            }

            &__label.error-message {
                font-size: rem-calc(24px);
            }
        }
    }
}
