@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "video-iframe";

.#{$object} {
  //styles copied from widen embed code
  width: 100%;
  height: 100%;
  border-radius: 6px;

  &__wrapper {
    position: relative;
    width: 100%;
    height: auto;
  }
}
