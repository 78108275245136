@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "rich-text";

.#{$object} {
  margin: rem-calc(0) auto;

  &--primary {
    background-color: $color-white;
  }

  &--secondary {
    background-color: $color-light;
    & * {
      color: $color-dark;
    }

    & h1,
    h2,
    h3,
    h4 {
      color: $color-primary;
    }
  }

  &--tertiary {
    background-color: $color-primary;

    a,
    & * {
      color: $color-white;
    }
    a {
      // text-decoration: none;
      font-weight: normal;
      &:hover,
      &:focus {
        text-decoration: underline;
        font-weight: $font-weight-bold;
      }
    }
    & h1,
    h2,
    h3,
    h4 {
      a {
        font-weight: inherit;

        &:hover,
        &:focus {
          font-weight: inherit;
          border: 1px dashed white;
        }
        &:focus {
          border: none;
          outline: 1px dashed white;
        }
      }
    }
  }

  &__wrapper {
    padding: rem-calc(20px) rem-calc($gutter-mobile);
  }

  img {
    max-width: 100%;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__wrapper {
      padding: rem-calc(20px) rem-calc($gutter-desktop);
      max-width: rem-calc($width-max);
      margin: rem-calc(0) auto;
    }
  }
}

.#{$object}.theme-tertiary {
}
