@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "desktop-search-refinement";

.#{$object} {
  margin-bottom: rem-calc(60px);

  &__title {
    color: $color-dark;
    font-size: rem-calc(21px);
    font-family: $font-primary;
    line-height: rem-calc(24px);
    margin: rem-calc(0px);    
  }

  &__options-wrapper {
    border-top: 1px solid $color-light;
    margin-top: rem-calc(16px);
    padding-top: rem-calc(4px);
  }

  &__item {
    background-color: $color-white;
    align-items: center;
    cursor: pointer;
    display: flex;
    min-height: rem-calc(50px);
    padding-inline: rem-calc(16px);

    & * {
      color: $color-dark;
    }

    &.active,
    &:hover {
      background-color: $color-primary;

      & * {
        color: $color-white;
      }
    }
  }

  &__icon {
    font-size: rem-calc(20px);
    margin-right: rem-calc(16px);
  }

  &__label {
    font-size: rem-calc(16px);
    font-weight: $font-weight-semi-bold;
    line-height: rem-calc(18px);
    margin: rem-calc(0px);
  }
}