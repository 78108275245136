@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "desktop-filter";

.#{$object} {
  background-color: $color-white;

  &__header {
    font-size: rem-calc(21px);
    line-height: rem-calc(24px);
    font-family: $font-secondary;
    color: $color-dark;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-bottom: rem-calc(15px);
  }
  &__group-wrapper {
    :first-child {
      border-top: 1px solid $color-light;
    }
  }
  &__group {
    :nth-of-type(1) {
      border-top: none;
    }
    border-bottom: 1px solid $color-light;
    padding: rem-calc(20px) rem-calc(10px);
  }

  &__group-heading {
    display: flex;
    justify-content: space-between;
  }

  &__group-btn {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem-calc(10px);
    cursor: pointer;
  }

  &__group-title {
    font-size: rem-calc(16px);
    line-height: rem-calc(18px);
    font-weight: $font-weight-extra-bold;
    font-family: $font-primary;
  }

  &__switch-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: rem-calc(5px);
  }
  &__switch {
    position: relative;
    display: inline-block;
    width: rem-calc(32px);
    height: rem-calc(16px);
    margin-top: rem-calc(5px);
  }
  &__switch > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  &__switch > input:checked + &__slider:before {
    -webkit-transform: translate(15px, 24%);
    -ms-transform: translate(15px, 24%);
    transform: translate(15px, 24%);
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: $color-primary;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;
    border-radius: 25px;
  }
  &__slider:before {
    position: absolute;
    content: "";
    height: rem-calc(11px);
    width: rem-calc(11px);
    left: rem-calc(3px);
    transform: translateY(24%);
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }

  &__filter-list-item {
    width: 100%;
  }

  &__filter-list-item-link {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-dark;
    font-family: $font-primary;
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(40px);
    text-decoration: none;
    width: 100%;
  }

  &__filter-list-item-link-icon {
    color: $color-dark;
    font-size: rem-calc(12px);
  }
}
