@import "../../sass/variables";
@import "../../sass/mixins";

$object: "toggle";

.#{$object} {
  background-color: $color-white;
  border: 2px solid transparent !important;
  display: flex;

  &__switch-wrapper {
    top: 13px;
    font-size: var(--baseSize);
    display: inline-block;
    vertical-align: middle;
    width: 32px;
    height: 16px;
    border-radius: 3.5em; /* = height */
    border: 1px solid $color-white;
    position: relative; // create positioning context for ::after
  }

  &__switch {
    display: block;
    position: absolute;
    top: 2px;
    left: 4px;
    right: 100%;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $color-white;
    transition: all 0.3s ease-out;
    z-index: 2;
  }

  &__switch-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 3.5em;
    background-color: $color-primary-dark;
    transition: all 0.1s ease-out;
  }

  &__switch-wrapper:focus {
    border: 3px solid $color-dark;
    top: -2px;
  }  
  .toggle__switch-checkbox__right:checked ~ .toggle__switch {
    right: 0;
    left: 50%;
  }
  
  label {
    font-size: .875rem;
    line-height: 1.375;
    margin: 0 .5em 0;
    cursor: pointer;
    padding-top: 13px;
    padding-bottom: 13px;
  }
  
  &__switch-wrapper > input[type="radio"] {
    display: inline-block;
    margin-right: -2px;
    width: 50%;
    height: 100%;
    opacity: 0;
    position: relative;
    z-index: 1;
    cursor: pointer;
   }

  &:focus-within {
    border: 2px solid $color-dark !important;
  }
}