@use "../../sass/variables" as *;
@use "../../sass/mixins" as *;

$object: "product-collection";

.#{$object} {
    --swiper-navigation-size: 20px;
    .swiper-wrapper {
        flex: 1;
        padding-top: 0px;
        max-height: 400px;
    }
    .swiper {
        padding: 2px;
    }
    
    .swiper-slide {
        height: auto !important;
    }

    &__inner {
        max-width: rem-calc($width-max);
        margin: rem-calc(0) auto;
    }

    &__title {
        font-size: rem-calc(21px);
        line-height: rem-calc(20px);
        margin: rem-calc(0);
        font-family: $font-primary;        

        &-wrapper {
            padding: rem-calc($gutter-mobile);
            text-align: center;
        }
    }

    &__carousel {
        box-sizing: border-box;
        background-color: $color-light;
        padding: rem-calc($gutter-mobile) rem-calc(20px);        

        &__card {
            position: relative;
            box-sizing: border-box;
            background-color: $color-white;
            border-radius: 6px;
            min-height: rem-calc(190px);
            padding: rem-calc(10px) rem-calc(10px) rem-calc(20px) rem-calc(10px);
            width: 100%;
            height: 100%;

            .add-product {
                position: absolute;
                top: rem-calc(10px);
                right: rem-calc(10px);
            }

            &__image {
                max-width: 100%;
                height: 90%;

                &-wrapper {
                    flex: auto 2 0;
                    flex-direction: column;
                    max-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__name {
                @include extra-small-font;
                margin: rem-calc(10px) 0 0;
                color: $color-dark;
                text-align: center;
            }

            &__link {
                display: flex;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__cta {
        &-wrapper {
            padding: rem-calc($gutter-mobile);
            text-align: center;
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        &__carousel__card__name {
            font-size: rem-calc(18px);
            line-height: rem-calc(23px);
        }
    }
    

    @media (min-width: map-get($breakpoints, "xlarge")) {
        --swiper-navigation-size: 30px;

        &__inner {
            display: grid;
            grid-template-columns: 25% 75%;
        }

        &__title {
            font-size: rem-calc(32px);
            line-height: rem-calc(30px);

            &-wrapper {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                text-align: left;
                padding: rem-calc(0) rem-calc(40px) rem-calc(0) rem-calc($gutter-desktop);
            }
        }

        &__cta {
            &-wrapper {
                padding: rem-calc(0);
                margin-top: rem-calc(20px);
            }
        }

        &__carousel {
            padding: rem-calc($gutter-desktop) rem-calc($gutter-desktop) rem-calc($gutter-desktop) rem-calc(40px);

            &__card {
                padding: rem-calc(20px) rem-calc(20px) rem-calc(40px) rem-calc(20px);
                min-height: rem-calc(300px);

                .add-product {
                    top: rem-calc(20px);
                    right: rem-calc(20px);
                }
            }
        }
    }
}