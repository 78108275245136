@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "product-tile";

.#{$object} {
  border-radius: 6px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  align-self: center;
  height: auto;
  max-width: 100%;
  text-decoration: none;
  position: relative;

  &--primary {
    border: 1px solid $color-light;
  }

  &__header {
    color: $color-dark !important; // important to override global theme font color
    font-size: rem-calc(16px);
    line-height: rem-calc(19px);
    font-weight: $font-weight-extra-bold;
    margin: rem-calc(5px) rem-calc(0);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__description {
    margin: rem-calc(0px) rem-calc(0px) rem-calc(30px);
  }

  &__description p {
    color: $color-medium;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    font-weight: $font-weight-normal;
    margin: rem-calc(0px);
  }

  &__image-wrapper {
    text-align: center;
  }

  &__image {
    aspect-ratio: 1;
    max-width: 100%;
    max-height: 100%;
    max-height: rem-calc(187px);
    object-fit: contain;
    max-inline-size: 100%;
    block-size: auto;
  }

  &--padding-side {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
  }

  &__cta:hover {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary;
    align-self: inherit;
    height: 100%;
    max-width: 100%;

    &__header {
      font-size: rem-calc(21px);
      line-height: rem-calc(24px);
      margin: rem-calc(20px) rem-calc(0px) rem-calc(10px);
    }

    &__description p {
      line-height: rem-calc(19px);
    }

    &__image {
      max-height: rem-calc(260px);
    }

    &--padding-side {
      padding-left: rem-calc(30px);
      padding-right: rem-calc(30px);
    }
  }
}
