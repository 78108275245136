@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "mobile-filter";

.#{$object} {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  overflow: auto;
  height: calc(100vh);
  &--hidden {
    display: none;
  }

  &__header-wrapper {
    border-bottom: 1px solid $color-light;
    padding: rem-calc(20px) rem-calc($gutter-mobile);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    font-family: $font-secondary;
    color: $color-primary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__header-btn {
    border: none;
    background: transparent;
    color: $color-dark;
    font-size: rem-calc(13px);
    &--hidden {
      visibility: hidden;
    }
  }

  &__filter {
    background-color: $color-white;
    overflow: auto;
    padding: rem-calc(10px) rem-calc($gutter-mobile);
  }

  &__filter-list {
    list-style-type: none;
    width: 100%;
    padding: rem-calc(0);
    margin: rem-calc(0);
  }

  &__filter-list-item {
    width: 100%;
  }

  &__filter-list-item-link {
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-dark;
    font-family: $font-primary;
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(40px);
    text-decoration: none;
    width: 100%;
  }

  &__filter-list-item-link-icon {
    color: $color-dark;
    font-size: rem-calc(12px);
  }
}
