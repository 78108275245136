@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'product-and-product-family';

.#{$object} {

    font-family: $font-primary;
    margin-inline: auto;
    max-width: 100%;

    // Wrapper
    &__wrapper {
        background-color: $color-white;
        border: 1px solid $color-light;
        border-radius: 6px;
        box-sizing: border-box;
        padding: rem-calc(10px);
        position: relative;
    }

    // Banners Container
    &__banners {
        position: absolute;
        top: rem-calc(45px);
        left: rem-calc(0px);
        z-index: 3;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }

    // Individual Banner
    &__banner {
        color: $color-white;
        border-radius: 0px 2px 2px 0px;
        font-size: rem-calc(11px);
        font-weight: $font-weight-extra-bold;
        line-height: rem-calc(15px);
        margin: rem-calc(5px) rem-calc(0px) rem-calc(0px);
        max-width: max-content;
        padding: rem-calc(3px) rem-calc(10px) rem-calc(3px) rem-calc(15px);
        pointer-events: auto;

        // Seconday - Gray Color
        &.secondary {
            background-color: $color-medium;
        }

        &.bottom {
          position: absolute;
          bottom: 100px;
        }

        @media (min-width: map-get($breakpoints, medium)) {
          &.bottom {
            position: absolute;
            bottom: 30px;
          }
        }

        // Primary - Blue Color
        &.primary {
            background-color: $color-primary;
        }

        // Icon
        &.icon {
            padding: 0 rem-calc(10px);
        }
        &.icon > img{
            width: rem-calc(28px);
        }
    }

    &__banner:first-child {
        margin: rem-calc(0px);
    }

    // Add Button
    &__button {
        position: absolute;
        left: rem-calc(10px);
        top: rem-calc(10px);

        >.add-product {
            position: relative;
            right: unset;
            top: unset;
        }
    }

    // Plus and Arrow-Down/Up Icons
    &__button-icon {
        &.down-icon {
            margin-left: rem-calc(10px);
            color: $color-dark;
        }

        &.checkmark {
            color: $color-primary;
            margin-right: rem-calc(10px);
        }
        
        &.darticon-proPlanet_Leaf {
            color: $color-proplanet-leaf;
            margin-right: rem-calc(8px);
            margin-left: rem-calc(-4px);
            font-size: 1.25rem;
            &.blue {
              color: $color-proplanet-blue;
            }
        }
    }

    // Product Container
    &__product {
        display: flex;
    }

    // Product Image Container
    &__product-image-container {
        height: fit-content;
        width:100%;
        height:100%;
    }

    // Product Image Wrapper
    &__product-image-wrapper {
        aspect-ratio: 1;
        position: relative;
    }

    // Product Image
    &__product-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }

    // Product Collection Banner
    &__product-collection {
        background-color: $color-light-background;
        box-sizing: border-box;
        bottom: rem-calc(0px);
        color: $color-medium;
        font-size: rem-calc(12px);
        font-weight: $font-weight-extra-bold;
        line-height: rem-calc(16px);
        margin: rem-calc(0px);
        padding: rem-calc(10px);
        position: static;
        text-align: center;
        width: 100%;
    }

    // Product Information Wrapper
    &__product-info {
        display: flex;
        flex-direction: column;
        max-width: rem-calc(400px);
        width: 100%;
    }

    // Product Name
    &__product-name {
        font-size: rem-calc(21px);
        font-family: $font-primary;
        font-weight: $font-weight-extra-bold;
        text-transform: none;
        margin: rem-calc(0px) rem-calc(0px) rem-calc(5px);
        line-height: rem-calc(19px);
        color: $color-dark;
    }

    // Product Body Texts
    &__product-body {
        font-size: rem-calc(12px);
        font-weight: $font-weight-medium;
        line-height: rem-calc(18px);
        margin: rem-calc(0px);

        // SKU
        &.sku {
            color: $color-medium;
            margin-bottom: rem-calc(24px);
        }

        // Promo Description
        &.promo-desc>* {
            color: $color-medium;
            font-size: rem-calc(12px);
            font-weight: $font-weight-medium;
            line-height: rem-calc(16px);
            margin: rem-calc(0px);
        }

        // Description
        &.desc>* {
            color: $color-dark;
            font-weight: $font-weight-semi-bold;
            margin: rem-calc(0px) rem-calc(0px) rem-calc(10px);
        }

        // Key Features
        &.feature-label {
            color: $color-dark;
            font-weight: $font-weight-extra-bold;
        }

        // Info
        &.info {
            color: $color-medium;
            line-height: rem-calc(18px);
        }
    }

    // Key Features List (ul)
    &__key-features {
        list-style-type: disc;
        margin: rem-calc(5px) rem-calc(0px) rem-calc(10px);
        padding-inline-start: rem-calc(25px);
    }

    // Key Feature (li)
    &__key-feature {
        color: $color-medium;
        font-size: rem-calc(14px);
        font-weight: $font-weight-medium;
        line-height: rem-calc(21px);
        width: 100%;
    }

    // View More / Less CTA
    &__view-more-less-cta {
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        font-weight: $font-weight-extra-bold;
        text-decoration: none;
        width: max-content;
    }

    // View More / Less CTA - Text
    &__view-more-less-text {
        font-size: rem-calc(14px);
        margin: rem-calc(0px);
        text-decoration: underline;
    }

    // Material Icons Container
    &__material-icons-container {
        margin-top: auto;
    }

    // Material Icon List (ul)
    &__material-icon-list {
        display: flex;
        flex-wrap: wrap;
        gap: rem-calc(2px);
        list-style: none;
        margin-top: rem-calc(10px);
        padding-inline-start: rem-calc(0px);
    }

    // Material Icon (span)
    &__material-icon {
        color: $color-primary;
        font-size: rem-calc(35px);
    }

    // Product & Sustainable Features Container
    &__product-sustainable-container {
        align-self: flex-end;
        margin: rem-calc(55px) rem-calc(0px) rem-calc(0px) auto;
        max-width: rem-calc(230px);
        width: 100%;
    }

    // Product & Sustainable Features (ul)
    &__product-sustainable-features {
        list-style-type: none;
        list-style-position: inside;
        margin: rem-calc(5px) rem-calc(0px) rem-calc(10px);
        padding-inline-start: rem-calc(0px);
    }

    // Product & Sustainable Feature (li)
    &__product-sustainable-feature {
        color: $color-dark;
        display: inline-flex;
        font-size: rem-calc(14px);
        font-weight: $font-weight-semi-bold;
        line-height: rem-calc(16px);
        margin-top: rem-calc(10px);
        width: 100%;
    }

    &__product-sustainable-feature:first-child {
        margin-top: rem-calc(0px);
    }

    // View Details CTA
    &__view-details-cta {
        margin-top: rem-calc(10px);
        padding: rem-calc(0px) rem-calc(10px);
        width: 100%;
    }

    // Desktop Breakpoint
    @media (min-width: map-get($breakpoints, medium)) {
        &__wrapper {
            padding: rem-calc($gutter-mobile);
        }

        &__banners {
          top: rem-calc(30px);
          pointer-events: none;
        }

        &__banner {
            font-size: rem-calc(14px);
            line-height: rem-calc(19px);
            margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
            padding: rem-calc(5px) rem-calc(15px) rem-calc(5px) rem-calc(20px);
            pointer-events: auto;
            // Icon
            &.icon > img{
                width: rem-calc(50px);
            }
        }

        &__button {
            left: unset;
            right: rem-calc(20px);
            top: rem-calc(20px);
        }

        &__button-icon {
            font-size: rem-calc(12px);
        }

        &__product-image-container {
            margin-right: min(2vw, 30px);
            max-width: rem-calc(260px);
            min-width: rem-calc(160px);
            width: rem-calc(260px);
        }

        &__product-info {
            margin-right: rem-calc($gutter-mobile);
        }

        &__product-name {
            margin: rem-calc(0px) rem-calc(0px) rem-calc(20px);
            line-height: rem-calc(24px);
        }

        &__product-body {
            font-size: rem-calc(14px);

            &.sku {
                margin-bottom: rem-calc(20px);
            }

            &.promo-desc>* {
                font-size: rem-calc(16px);
                line-height: rem-calc(24px);
            }

            &.desc>* {
                margin-bottom: rem-calc(20px);
            }
        }

        &__material-icon {
            font-size: rem-calc(40px);
        }

        &__view-details-cta {
            width: 100%;
        }
    }
}