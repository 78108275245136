@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'general-flyout';

.#{$object} {
  &__header-wrapper {
    border-bottom: 1px solid $color-light;
    padding: rem-calc(30px) rem-calc($gutter-mobile);
  }

  &__back-btn {
    border: none;
    background: transparent;
    font-size: rem-calc(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    color: $color-dark;
  }

  &__header-line-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__header {
    font-size: rem-calc(21px);
    line-height: rem-calc(20px);
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-transform: uppercase;
  }

  &__columns {
    display: flex;
    flex-direction: column;
    padding: rem-calc(20px) rem-calc($gutter-mobile);
  }

  &__column {
    margin-top: rem-calc(30px);

    &:first-child {
      margin-top: rem-calc(0);
    }
  }

  &__column-header {
    font-size: rem-calc(18px);
    line-height: rem-calc(18px);
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-transform: uppercase;
    margin: 0;
  }

  &__column-list {
    margin: rem-calc(0) rem-calc(0) rem-calc(8px) rem-calc(0);
    padding: rem-calc(0);
    list-style-type: none;
  }

  &__column-list-item {}

  &__column-list-item-link {
    font-size: rem-calc(16px);
    line-height: rem-calc(40px);
    font-weight: $font-weight-semi-bold;
    color: $color-dark;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover {
      text-decoration: underline;
    }
  }


  @media (min-width: map-get($breakpoints, large)) {
    &__header-wrapper {
      display: none;
    }

    &__columns {
      display: flex;
      flex-direction: row;
      margin: rem-calc(0) auto;
      max-width: rem-calc($width-max);
      padding: rem-calc(60px) rem-calc($gutter-desktop) rem-calc(55px);
    }

    &__column {
      flex-basis: 25%;
      margin-top:0;
    }
  }
}