@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "filter-pills";
$pill-spacing: 10px;

.#{$object} {
    display: inline;
    margin: rem-calc(0);
    padding: rem-calc(0);
    list-style: none;

    &__wrapper {
        margin-bottom: rem-calc(-$pill-spacing);
    }

    &__item {
        display: inline-block;
        margin: rem-calc(0) rem-calc($pill-spacing) rem-calc($pill-spacing) rem-calc(0);
        border-radius: 4px;
        background-color: $color-light-background;

        &__button {
            display: flex;
            align-items: center;
            padding: rem-calc(11px) rem-calc(12px);
            border-radius: 4px;
            background: transparent;
            border: none;
            cursor: pointer;
            color: $color-dark;
            font-family: $font-primary !important;
            @include small-font;

            &:before {
                display: flex;
                align-items: center;
                background-color: transparent;
                margin-right: rem-calc(10px);
                font-size: rem-calc(7px);
                font-family: '#{$icomoon-font-family}';
            }
        }
    }

    &__clear-button {
        padding: rem-calc(11px) rem-calc(0);
        border: none;
        height: auto;
    }
}
