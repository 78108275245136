@use "../../sass/_variables"as *;
@use "../../sass/_mixins"as *;

$object: "region-selector";

.#{$object} {
    padding: rem-calc(0) rem-calc($gutter-mobile);

    &__wrapper {
        padding: rem-calc(30px) rem-calc(0) rem-calc(65px);
    }

    &__title {
        font-size: rem-calc(28px);
        line-height: rem-calc(27px);
        margin: rem-calc(0) rem-calc(0) rem-calc(10px);
    }

    &__subtitle {
        margin: rem-calc(0);
        @include medium-font;
    }

    &__regions {
        margin: rem-calc(30px) rem-calc(0) rem-calc(0);

        &__column {
            margin-bottom: rem-calc(40px);

            &:last-of-type {
                margin-bottom: rem-calc(0);
            }

            &__header {
                font-size: rem-calc(18px);
                line-height: rem-calc(18px);
                color: $color-dark;                
                font-family: $font-primary;
                margin: rem-calc(0) rem-calc(0) rem-calc(5px);
            }

            &__list {
                margin: rem-calc(0);
                padding: rem-calc(0);
                list-style: none;
                color: $color-dark;

                &__item {
                    line-height: rem-calc(40px);

                    * {
                        color: $color-dark;
                    }

                    &>a,
                    &>div {
                        font-weight: $font-weight-bold;

                        &.no-link {
                            font-weight: $font-weight-medium;
                        }
                    }

                    &__link {
                        &>span {
                            font-weight: $font-weight-medium;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, "large")) {
        padding: rem-calc(0) rem-calc($gutter-desktop);

        &__wrapper {
            padding: rem-calc(40px) rem-calc(0) rem-calc(120px);
        }

        &__title {
            font-size: rem-calc(48px);
            line-height: rem-calc(46px);
            margin: rem-calc(0) rem-calc(0) rem-calc(20px);
        }

        &__subtitle {
            font-size: rem-calc(21px);
            line-height: rem-calc(24px);
        }

        &__regions {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: rem-calc(40px);
            margin: rem-calc(60px) rem-calc(0) rem-calc(0);

            &__column {
                margin-bottom: rem-calc(0px);

                &__header {
                    font-size: rem-calc(21px);
                    line-height: rem-calc(18px);
                    margin: rem-calc(0) rem-calc(0) rem-calc(10px);
                }

                &__list {
                    &__item {
                        &__link {
                            display: inline;
                        }
                    }
                }
            }
        }
    }
}