@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "form-embed";

.#{$object} {
  padding: rem-calc(40px) rem-calc(0);

  &__wrapper {
    max-width: rem-calc($width-max-content);
    padding: rem-calc(0) rem-calc($gutter-mobile);
    margin-inline: auto;
  }

  &__right {
    background-color: $color-white;
    z-index: 1;
    padding: rem-calc(20px);
    border-radius: 6px;

    &--primary {
      border: 1px solid $color-light;
    }
    h1, h2, h3{
      color:$color-primary;
    }
    p{
      color:$color-dark;
    }
  }

  &__logo {
    width: max-content;
    max-width: 100%;
  }

  &__header,
  &__description {
    max-width: 95%;
  }

  &__header {
    margin-block-end: rem-calc(0);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(0px);
  }

  &__sec-title {
    font-size: rem-calc(21px);
    line-height: rem-calc(24px);
    font-weight: $font-weight-extra-bold;
  }

  &__description {
    margin: rem-calc(10px) rem-calc(0px) rem-calc(20px);
    li {
      display: list-item;
    }
  }

  &__description > * {
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    margin: rem-calc(0px);
    font-weight: $font-weight-medium;
  }

  &__hidden {
    visibility: hidden;
  }

  &__iframe-wrapper {
    width: 100%;
    height: rem-calc(700px);
  }

  &__confirmation {
    text-align: center;
  }

  &__confirmation-icon {
    color: $color-primary;
    border: 4px solid $color-primary;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
  }

  &__confirmation-title {
    color: $color-primary !important;
    font-family: $font-primary;    
    font-size: rem-calc(32px);
    line-height: rem-calc(31px);
  }

  &__confirmation-description > * {
    color: $color-medium !important;
    font-weight: $font-weight-medium;
  }

  &__confirmation-cta {
    font-size: rem-calc(16px);
    margin-top: rem-calc(20px);
    text-transform: capitalize;
  }

  &__confirmation-cta-icon {
    font-size: rem-calc(32px);
    margin-top: rem-calc(7px);
  }

  @media (min-width: map-get($breakpoints, medium)) {
    padding: rem-calc(60px) rem-calc(0);

    &__wrapper {
      display: flex;
      padding: rem-calc(0) rem-calc($gutter-desktop);
      gap: rem-calc(20px);
    }

    &__left {
      flex: 1;
      display: flex;
      flex-direction: column;
      flex: 1px 1px 0px;
    }

    &__right {
      width: 40%;
      padding: rem-calc(60px);
    }

    &__header,
    &__description {
      max-width: rem-calc(500px);
    }

    &__header {
      margin-top: rem-calc(30px);
    }

    &__description {
      margin-bottom: rem-calc(0px);
    }

    &__description > * {
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }

    &__image {
      max-width: 100%;
      float: right;
      clear: right;
      align-self: flex-end;
      margin-top: auto;
      margin-right: rem-calc(-60px);
      margin-bottom: rem-calc(-60px);
    }
  }
}

.#{$object}.theme-tertiary{
  .#{$object}__description a{
      color: $color-white;
      // text-decoration: none;
      font-weight: normal;
      &:hover{
        font-weight: $font-weight-bold;
        text-decoration: underline;
      }      
  }
}

#mc_embed_signup {
    input, select {
      border-color: $color-medium;

      &:active,
      &:focus,
      &:focus-visible {
        border-color: $color-dark;
        border: 2px solid;
      }
    }
}