@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "search-input-filter";

.#{$object} {
  font-family: $font-primary;
  margin-bottom: rem-calc(30px);

  // Label
  &__label {
    font-size: rem-calc(14px);
    font-weight: $font-weight-extra-bold;
    line-height: rem-calc(18px);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(5px);
    text-transform: capitalize;
  }

  // Input & Search Icon Container
  &__input-icon-container {
    align-items: center;
    border: 1px solid $color-light;
    border-radius: 6px;
    box-sizing: border-box;
    display: flex;
    padding-left: rem-calc($gutter-mobile);
    width: 100%;
  }

  // Icon
  &__search-icon {
    color: $color-dark;
    font-size: rem-calc(14px);
    &:hover{
      cursor: pointer;
    }
  }

  // Input
  &__input {
    border: none;
    padding-left: rem-calc(10px);
    width: 100%;
  }
  &__input:focus {
    outline: none;
  }

  &__clear-button {
    background-color: transparent;
    border: none;
  }

  &__clear-icon {
    color: $color-dark;
    font-size: rem-calc(10px);
    padding-right: 10px;
    &:hover{
      cursor: pointer;
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    margin-bottom: rem-calc(40px);

    &__input-icon-container {
      max-width: 100%;
    }
  }
}