@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "article-tile";

.#{$object} {
  border-radius: 6px;
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  align-self: center;
  height: auto;
  max-width: 100%;
  text-decoration: none;

  &--primary {
    border: 1px solid $color-light;
  }

  &__publication-info {
    color: $color-primary !important; // important to override global theme font color
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    font-weight: $font-weight-bold;
    margin: rem-calc(0);
    margin-top: rem-calc(20px);
  }

  &__header {
    color: $color-dark !important; // important to override global theme font color
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    text-transform: capitalize;
    font-weight: $font-weight-extra-bold;
    margin: rem-calc(5px) rem-calc(0);
  }

  &__description {
    margin: rem-calc(0px) rem-calc(0px) rem-calc(20px);
  }

  &__description p {
    color: $color-medium;
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    font-weight: $font-weight-medium;
    margin: rem-calc(0px);
  }

  &__image {
    width: 100%;
    border-radius: 6px 6px 0 0;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
  }

  &--padding-side {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
  }

  &__cta:hover {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary;
    align-self: inherit;
    height: 100%;
    max-width: 100%;

    &__header {
      font-size: rem-calc(21px);
      line-height: rem-calc(24px);
      margin: rem-calc(20px) rem-calc(0px) rem-calc(10px);
    }

    &__description {
      margin: rem-calc(0px) rem-calc(0px) rem-calc(50px);
    }

    &__description p {
      line-height: rem-calc(19px);
    }

    &__image {
      max-height: rem-calc(250px);
    }
  }
}
