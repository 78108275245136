@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "contact-link";

.#{$object} {
  position: fixed;
  right: rem-calc(5px);
  bottom: rem-calc(20px);
  width: rem-calc(45px);
  height: rem-calc(45px);
  background: $color-primary;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  border: 3px solid $color-white;
  border-radius: 6px;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.5s ease-in-out;
  z-index: 10;

  &:hover {
    background: $color-primary-dark;
  }

  &--scrolled{
    bottom: rem-calc(85px);
  }

  &__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: rem-calc(10px);
    border: 0;
    background-color: transparent;
    padding: rem-calc(0);
    border-radius: 6px;
    cursor: pointer;
    text-decoration: none;
    font-weight:400;
    line-height: 1.15;
    text-align: center;
    color: $color-white;

    &__icon {
      display: inline-block;
      margin: rem-calc(0) auto;
      width: rem-calc(20px);
      height: rem-calc(10px);
      font-size: 1.4em;
    }

    &__label {
      display: none;
    }

    &__wrapper{
      width: 100%;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border: 2px solid $color-dark;
      outline: 2px solid $color-dark;
    }
  }

  @media (min-width: map-get($breakpoints, medium )) {
    width: rem-calc(44px);
    height: rem-calc(54px);

    &__link {
      &__icon {
        margin: rem-calc(0) auto rem-calc(8px) auto;
      }

      &__label {
        display: inline;
        line-height: rem-calc(11px);
      }
    }
  }

  @media(max-width: map-get($breakpoints, medium)) {
    display: none;
  }
}
