@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "filter-options";

.#{$object} {
  background-color: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: calc(100vh);

  &__header-wrapper {
    border-bottom: 1px solid $color-light;
    padding: rem-calc(20px) rem-calc($gutter-mobile);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    font-family: $font-secondary;
    color: $color-primary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }

  &__header-btn {
    border: none;
    background: transparent;
    color: $color-dark;
    font-size: rem-calc(13px);
  }

  &__item-wrapper {
    background-color: $color-white;
    padding: rem-calc(30px) rem-calc($gutter-mobile);

    & label {
      color: $color-medium;
      font-weight: $font-weight-semi-bold;
    }
  }

  &__btn {
    margin-top: rem-calc(20px);
    width: 100%;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    height: auto;
    position: relative;

    &__item-wrapper {
      background-color: $color-white;
      padding: rem-calc(20px) rem-calc(27px);

      & label {
        color: $color-medium;
        font-weight: $font-weight-semi-bold;
      }
    }
  }
}
