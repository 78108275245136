@import "../../sass/variables";
@import "../../sass/mixins";

$object: "footer-column";
.#{$object} {
  @media (min-width: map-get($breakpoints, medium)) {
  }

  @media (min-width: map-get($breakpoints, large)) {
    flex: 1 1 0px;
  }
}
