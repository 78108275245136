@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "mobile-search-refinement";

.#{$object} {
  margin-top: rem-calc(20px);

  &__results-count {
    color: $color-dark;
    font-weight: $font-weight-medium;
    line-height: rem-calc(24px);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(20px);
    text-transform: capitalize;
  }

  &__select {
    background: $color-white;
    font-size: rem-calc(16px);
    font-weight: $font-weight-extra-bold;
    height: rem-calc(50px);
    line-height: rem-calc(18px);
    padding: rem-calc(0px) rem-calc(10px);
    width: 100%;
  }

  &__option {
    font-weight: $font-weight-extra-bold;
  }
}