@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'search';

.#{$object} {
  position: relative;

  &__loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    display: flex;
    justify-content: center;
  }
  &__loading-overlay-background{
    background-color: $color-medium;
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    display: flex;
    justify-content: center;
  }

  &__inner {
    max-width: rem-calc($width-max);
    margin: rem-calc(0) auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: rem-calc(0) rem-calc($gutter-mobile);
    position: relative;
  }

  &__results-wrapper {
    margin-bottom: rem-calc(60px);
  }

  &__results-list {
    padding: rem-calc(0);
    margin: rem-calc(0);
    position: relative;
    list-style-type: none;
    display: flex;
    gap: rem-calc($gutter-mobile);
    flex-wrap: wrap;
  }

  &__results-list-item {
    width: 100%;
    max-width: 100%;

    &:first-child {
      margin-top: rem-calc(0);
    }

    &.document {
      box-sizing: border-box;
      height: 100%;
      margin-top: rem-calc(0px);
    }
  }

  &__load-more-wrapper {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__load-more {
    margin-top: rem-calc(20px);
  }

  @media (min-width: map-get($breakpoints, medium-small)) {
    &__results-list-item {
      &.document {
        max-width: calc((100% / 2) - 10px);
      }
    }
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__results-list {
      margin-top: rem-calc($gutter-mobile);
    }
  }

  @media (min-width: map-get($breakpoints, large)) {
    &__results-list-item {
      &.document {
        max-width: calc((100% / 3) - 13.4px);
      }
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    &__inner {
      flex-direction: row;
      justify-content: space-between;
      padding: rem-calc(0) rem-calc($gutter-desktop);
    }

    &__top-bar {
      width: 100%;
    }

    &__left-rail {
      width: 25%;
      box-sizing: border-box;
    }

    &__results-wrapper {
      width: 75%;
      padding-left: rem-calc(45px);
      box-sizing: border-box;
      margin-top: -90px;
    }

    &__results-list {
      margin-top: rem-calc(0px);
    }

    &__load-more-wrapper {
      position: relative;
      z-index: 5;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__load-more {
      margin-top: rem-calc(40px);
    }
  }

  .no-results__aid {
    display: flex;
    flex-direction: column;
    min-width: 100%;
  }
}
