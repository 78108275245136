@import "../../../sass/variables";
@import "../../../sass/mixins";
$object: "hot-spots";
.#{$object} {
  width: 100%;
  height: 100%;
  grid-row: 1;
  grid-column: 1;
  position: relative;
  .__spot {
    width: 1px;
    height: 1px;
    position: relative;
    .__item {
      width: 25px;
      height: 25px;
      left: -12.5px;
      top: -12.5px;
      position: relative;
      cursor: pointer;
      display: flex;
      border: none;
      background-color: rgba(0, 0, 0, 0.0);
      .__layer {
        width: 25px;
        height: 25px;
        position: absolute;
        &--3 {
          clip-path: circle(20%);
          background: white;
        }
        &--2 {
          clip-path: circle(25%);
          background: rgba(0, 0, 0, 0.5);
        }
        &--1 {
          border-radius: 50%;
          background: rgba(127, 127, 127, 0.5);
          border: 1px solid white;
          left: -1px;
          top: -1px;
          margin: auto;
          width: 100%;
          height: 100%;
        }
      }

      &--active{

        &:hover,
        &:focus {
          .__layer {
            transition: all 0.2s linear;
            &--3 {
              clip-path: circle(15%);
            }
            &--2 {
              clip-path: circle(20%);
            }
            &--1 {
              background: rgba(64, 127, 188, 0.75);
            }
          }
        }
      }
    }
  }
  .__hot-spot-content {
    overflow-y: hidden;
    background: white;
    max-width: 50%;
    max-height: 50%;
    position: absolute;
    box-shadow: 0 0 5px #333;
    border-radius: 3px;
    opacity: 0;
    padding: 0.5rem;
    transition: opacity 0.5s;
    &--active{
      opacity: 100;
    }


    .__playbtn{
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: auto;
      grid-template-rows: auto;
      cursor: pointer;
      .___circle{
        width: 40px;
        height: 40px;
        background: white;
        border-radius: 50%;
        grid-column: 1;
        grid-row: 1;
        align-self: center;
        justify-self: center;
        cursor: pointer;
      }
      .__triangle{
        grid-column: 1;
        grid-row: 1;
        cursor: pointer;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        
        border-left: 18px solid darkgray;
        align-self: center;
        position: relative;
        justify-self: center;
        left: 2px;
      }
      img{
        grid-column: 1;
        grid-row: 1;
      }
      
    }

    .__close {
      position: sticky;
      right: 10px;
      top: 5px;
      float: right;
      display: none;
      cursor: pointer;
      &:hover {
        color: black;
      }
    }

    @media (max-width: map-get($breakpoints, medium-small)) {
      width: 90%;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      top: 0 !important;
      left: 0 !important;
      max-width: 90%;
      max-height: 90%;
      right: 0;
      bottom: 0;
      margin: auto;
      .__close {
        display: block;
      }
    }

    .__content {
      line-height: normal;
      display: flex;
      align-items: center;
      justify-items: center;
      justify-content: center;
      width: 100%;

      &--mock{
        display: block;
      }

      .__tags{
        position: absolute;
        left:0;
        &--top{
          top: 5px;
        }
        &--bottom{
          bottom: 5px;
        }
      }

      .__tag {
        color: $color-white;
        background: $color-medium;
        font-weight: $font-weight-semi-bold;
        font-size: 0.7rem;
        text-align: center;
        padding: 3px;

        &--new {
          background: $color-site-message-info;
          width: 80px;
        }
        &--brand {
          width: 90px;
          margin-top: 10px;
        }
        &--planet {
          position: absolute;
          width: 25px;
          background: white;
          color: $color-proplanet-leaf;
          img {
            width: 30px;
          }
        }
      }
      .__main {
        display: grid;
        grid-template-columns: auto 44px;
        justify-content: space-evenly;
        .__title,
        .__subtitle,
        .__productcode {
          margin-left: 5px;
          margin-bottom: 0;
          margin-top: 0;
          padding: 0;
          @include small-font;
        }

        .__details {
          @include extra-small-font;
          margin-left: 5px;
        }
        .__circle{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          background:lightgray;
          align-self: center;
          justify-self: center;
        }
        .__bar{
          width: 100px;
          height: 15px;
          margin: 5px 0;
          padding-left: 5px;
          background:lightgray;

          &--margin {
            margin-bottom: 10px;
          }

          &--thin {
            height: 10px;
          }
          &--long {
            width: 150px;
          }
          &--dark {
            background:gray;
          }
        }
        .__media {
          &--mock{
            justify-content: center;
            align-content: center;
            display: flex;
            div{
              background: lightgray;
              height: 60px;
              width: 60px;
            }                        
          }

          .video-iframe__wrapper{
            max-width: 122px;
            max-height: 122px;            
            align-self: center;
            justify-self: center;
            justify-content: center;
            border-radius: 5px;
            align-content: center;
            display: flex;
          }
          .__media-content{
            &--image{
              width: 100%;
              max-width: 122px;
              max-height: 122px;
            }
  
            &--image-clickable{
              cursor: pointer;
            }
            width: 100%;
          }
          a{

            align-self: center;
            justify-self: center; 
            img{
              width: 100% !important;
            }
          }
        }
        .__childproductname,
        .__subtitle {
          color: $color-medium;
          @include extra-small-font;
          margin-left: 5px;
          font-weight: $font-weight-normal;
        }

        .__desc {
          @include extra-small-font;
          color: $color-dark;
          padding-left: 5px;
        }

        .__arrow{

          background: #fd5;
          text-align: center;
          font-weight: 100;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.125rem;
          padding: 0.3125rem;
          height: 1.75rem;
          width: 1.75rem;
          max-width: 40px;
          max-height: 40px;
          .__a-icon{
            position: relative;
            top: -2px;
          }
        }

        .__body {
          margin-left: 5px;
          p {
            @include extra-small-font;
          }
        }

        &--withmedia {
          display: grid;
          grid-template-columns: auto auto 44px;
          grid-template-rows: auto;
          align-items: center;
          justify-content: space-evenly;

          .__media {
            grid-column: 1;
            display: grid;
          }
          .__title,
          .__productcode,
          .__childproductname {
            grid-column: 2;
          }
        }
        &--5050{
          grid-template-columns: 50% 50%;

          
          .__media {
            grid-column: 1;
            display: grid;
            .video-iframe__wrapper{
              max-width: 200px;
              max-height: 200px;            
            }

            .__media-content {
              justify-self:center;
              align-self: center;
              height: auto;

              &--image{
                max-width: 200px;
              }
            }
          }
          .__title,
          .__productcode,
          .__childproductname {
            grid-column: 2;
          }

        }
        .__ctaLink {
          align-self: center;
          justify-self: center;
          text-decoration: none;
        }
      }
    }
  }

}
