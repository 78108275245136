@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "authored-tile-container";

.#{$object} {
  margin: rem-calc(0) auto;

  &__wrapper {
    padding: rem-calc($gutter-mobile);
  }

  &__heading {
    font-size: rem-calc(28px);
    line-height: rem-calc(26px);
    color: $color-primary;    
    font-weight: $font-weight-bold;    
    margin-bottom: rem-calc(35px);
    margin-top: rem-calc(35px);
  }

  &__tiles {
    display: grid;
    grid-auto-rows: 1fr;
    column-gap: rem-calc(10px);
    row-gap: rem-calc(10px);
  }

  &__tiles--simple {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__tiles--detailed {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__wrapper {
      padding: rem-calc($gutter-desktop);
      max-width: rem-calc($width-max);
      margin: rem-calc(0) auto;
    }

    &__heading {
      font-size: rem-calc(42px);
      line-height: rem-calc(40px);
    }

    &__tiles {
      grid-auto-rows: 1fr;
      grid-column-gap: rem-calc(20px);
      grid-row-gap: rem-calc(20px);
    }

    &__tiles--simple {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &__tiles--detailed {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: map-get($breakpoints, large)) {
    &__tiles--simple {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: map-get($breakpoints, xxlarge)) {
    &__tiles--simple {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
