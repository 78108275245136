@import "../../sass/variables";
@import "../../sass/mixins";

$object: "saved-products-controller";

.#{$object} {

  padding: $gutter-mobile;

  // Saved Products Wrapper
  &__wrapper {
    margin: auto;
    max-width: $width-max;
  }

  // Header Wrapper
  &__header-wrapper {
    display: flex;
    flex-direction: column;
  }

  // Saved Products Title
  &__header-text {
    font-size: rem-calc(28px);
    line-height: rem-calc(27px);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(10px);
    max-width: max-content;
  }

  // Print and Clear List CTAs
  &__header-cta {
    background-color: $color-white;
    border: none;
    cursor: pointer;
  }

  &__header-cta:first-of-type, &__header-cta:nth-of-type(2) {
    margin-right: rem-calc(30px);
  }

  &__header-cta-icon {
    font-size: rem-calc(14px);
    margin-right: rem-calc(10px);
  }

  &__header-cta-label {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  // Products List
  &__products-wrapper {
    margin-top: rem-calc(30px);
  }

  &__products-list {
    list-style: none;
    list-style-position: inside;
    margin: rem-calc(0px);
    padding-inline-start: rem-calc(0px);
  }

  &__product {
    margin-bottom: rem-calc(20px);
  }

  // Load More Wrapper
  &__load-more-wrapper {
    margin: rem-calc(60px) auto rem-calc(0px);
    max-width: max-content;
  }

  &__share-this-modal > .modal__main {
    width: rem-calc(600px);
  }

  &__share-this-title {
    max-width: rem-calc(450px);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }

  &__share-this-form {
    max-width: rem-calc(450px);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: rem-calc(50px);
  }

  &__share-this-submit, &__share-this-form label {
    margin-top: rem-calc(15px);
  }

  &__share-this-submit {
    float: right;
    margin-bottom: rem-calc(30px);
  }
  &__share-this-input {
    width: calc(100% - rem-calc(24px));
  }

  &__share-this-input:disabled {
    background-color: $color-light;
    color: $color-dark;
  }

  &__share-this-input.error {
    border-color: $color-error;
  }

  &__share-this-submit:disabled {
    color: $color-white;
    border-color: $color-medium;
    background: $color-medium;
    cursor: default;
  }

  &__share-this-validation {
    color: $color-error;
  }

  &__share-this-notice {
    font-style: italic;
    font-size: .9rem;
  }

  // Desktop Breakpoint
  @media (min-width: map-get($breakpoints, medium)) {
    padding: rem-calc(40px) $gutter-desktop;

    &__header-wrapper {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__header-text {
      font-size: rem-calc(48px);
      line-height: rem-calc(46px);
      margin: rem-calc(0px) rem-calc(20px) rem-calc(10px) rem-calc(0px);
    }

    &__products-wrapper {
      margin-top: rem-calc(40px);
    }

    &__load-more-wrapper {
      margin: rem-calc(40px) auto rem-calc(40px);
    }
  }

}