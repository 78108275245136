@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "add-product";

.#{$object} {
    box-sizing: border-box;
    width: rem-calc(40px);
    height: rem-calc(40px);
    border: 2px solid $color-dark;
    border-radius: 4px;
    position: absolute;
    top: rem-calc(20px);
    right: rem-calc(20px);
    z-index: 3;
    transition: all 0.5s ease;

    &:not(&--full) {
        overflow: hidden;
    }

    &__add-link {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        text-decoration: none;
        border: none;
        background-color: $color-white;
        padding: rem-calc(0);
        transition: padding 0.5s ease;

        &:hover, &:focus {
            border: 2px solid white;
            outline: 2px solid white;
            background-color: $color-medium;
            cursor: pointer;
            color: white;
        }

        &__label {
            display: none;
            font-size: rem-calc(14px);
            line-height: rem-calc(28px);
        }

        &__icon {
            font-size: rem-calc(11px);

            &--hover {
                display: none;
            }
        }
    }

    &--small {
        width: rem-calc(24px);
        height: rem-calc(24px);
    }

    &--medium {
        width: rem-calc(40px);
        height: rem-calc(40px);
    }

    &--large {
        width: rem-calc(50px);
        height: rem-calc(50px);
    }

    &--full {
        position: relative;
        width: 100%;
        border: none;
        height: auto;
        top: 0;
        right: 0;

        > .#{$object}__add-link-full {
            height: rem-calc(50px);
        }
    }

    &--saved {
        border-color: $color-primary;

        .#{$object}__add-link {
            color: $color-white;
            background-color: $color-primary;
        }
    }

    &__add-link-full {
        font-weight: $font-weight-bold;
        font-size: rem-calc(16px);
        line-height: rem-calc(22px);
        width: 100%;
        height: 100%;
        padding: rem-calc(0) rem-calc(25px);

        .#{$object}__add-link-icon {
            margin-right: rem-calc(5px);
        }

        &--expandable {
            justify-content: space-between;
        }

        &--discontinued {
            background-color: $color-light;
            border-color: $color-light;
            color: $color-medium;

            &:hover {
                background-color: $color-light;
                border-color: $color-light;
                cursor: default;
            }
        }
    }

    &__dropdown {
        box-sizing: border-box;
        position: absolute;
        top: rem-calc(55px);
        width: 100%;

        * {
            box-sizing: border-box;
        }

        &__list {
            margin: rem-calc(0);
            padding: rem-calc(0);
            border: 1px solid $color-dark;
            border-radius: rem-calc(6px);

            &__item {
                list-style: none;

                &:nth-child(odd) {
                    background-color: $color-light-background;
                }

                &:nth-child(even) {
                    background-color: $color-white;
                }

                &:first-child {
                    border-radius: 6px 6px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 6px 6px;
                }

                &__button,
                &__anchor {
                    width: 100%;
                    padding: rem-calc(15px) rem-calc(25px);
                    cursor: pointer;
                    font-weight: $font-weight-medium;

                    &__icon {
                        font-size: rem-calc(12px);
                        margin-right: rem-calc(15px);
                    }
                }

                &__anchor {
                    display: inline-block;
                    text-decoration: none;
                }

                &__button {
                    background-color: transparent;
                    border: none;
                    text-align: left;
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        &:hover {
            width: auto;

            .#{$object}__add-link {
                padding: rem-calc(0) rem-calc(20px);

                &__label {
                    width: auto;
                    height: auto;
                    visibility: visible;
                    opacity: 1;
                    margin-left: rem-calc(5px);
                }

                &__icon {
                    display: none;

                    &--hover {
                        display: inline;
                    }
                }
            }
        }

        &--full {
            width: auto;
            max-width: rem-calc(250px);
        }

        &__add-link-full {
            width: 100%;

            &--expandable {
                padding: rem-calc(0) rem-calc(20px) rem-calc(0) rem-calc(30px);
            }
        }

        &__add-link {
            overflow: hidden;

            &__label {
                display: inline;
                width: 0;
                height: 0;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0.5s ease;
            }
        }
    }

    @media (min-width: map-get($breakpoints, "xxlarge")) {
        &:hover {
            .#{$object}__add-link {
                padding: rem-calc(0) rem-calc(30px);
            }
        }
    }
}
