@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'mobile-navigation';

.#{$object} {
  background-color: $color-white;
  position: relative;

  &__top-bar {
    border-bottom: 1px solid $color-light;
    height: rem-calc($topBarHeightMobile);
  }

  &__top-bar-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: rem-calc(0) rem-calc($gutter-mobile);
    height: 100%;
  }

  &__hamburger {
    border: none;
    background-color: transparent;
  }

  &__hamburger,
  &__search {
    text-decoration: none;
    color: $color-primary;
    font-size: rem-calc(24px);
    background:transparent;
    border: none;
  }

  &__logo {
    height: rem-calc(45px);
    display: block;
  }

  &__nav {
    height:calc(100vh - #{$topBarHeightMobile  + 1px}); //takes up whole vh
    position: fixed;
    top: calc($topBarHeightMobile + 1px); //need extra pixel for border
    left: 0;
    width: 100%;
    background-color: $color-white;
    overflow: auto;
  }

  &__nav-wrapper {
    position: relative;
  }

  &__nav-list {
    list-style-type: none;
    width: 100%;
    padding: rem-calc(0);
    margin: rem-calc(0);
  }

  &__nav-list-item {
    width: 100%;
  }

  &__nav-list-item-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem-calc(10px) rem-calc($gutter-mobile);
    color: $color-dark;
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    font-size: rem-calc(16px);
    line-height: rem-calc(40px);
    text-decoration: none;
    text-transform: uppercase;
    background-color: transparent;
    border:none;
    width: 100%;
  }

  &__nav-list-item-link-icon {
    color: $color-dark;
    font-size: rem-calc(12px);
  }

  &__utility-list {
    list-style-type: none;
    width: 100%;
    margin: rem-calc(0);
    padding: rem-calc(0);
    background-color: $color-primary;
  }

  &__utility-list-item {
    width: 100%;

    &:first-child {
      .#{$object}__utility-list-item-link {
        border-top: none;
      }
    }
  }

  &__utility-list-item-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: rem-calc(10px) rem-calc($gutter-mobile);
    color: $color-white;
    font-family: $font-primary;
    font-weight: $font-weight-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(40px);
    text-decoration: none;
    border-top: 1px solid $color-light;
  }

  &__utility-list-item-link-icon {
    margin-right: rem-calc(8px);
    font-size: rem-calc(18px);
  }

  &__search-wrapper {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-white;
    overflow: auto;
  }

  &__flyout-wrapper {
    background-color: $color-white;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}