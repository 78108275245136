@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'product-detail-features-benefits';

.#{$object} {

  padding: rem-calc(60px) rem-calc($gutter-mobile);

  // Wrapper
  &__wrapper {
    margin: auto;
    max-width: rem-calc($width-max);
  }

  // Component Title
  &__title {
    line-height: rem-calc(27px);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(40px);
  }

  // Content Wrapper
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    gap: rem-calc($gutter-mobile);
    margin: rem-calc(40px) rem-calc(0px) rem-calc(0px);

    &:first-child {
      margin: rem-calc(0px);
    }
  }

  // Image
  &__image {
    aspect-ratio: 1.3;
    box-sizing: border-box;
    height: 100%;
    margin: rem-calc(0px);
    max-height: rem-calc(375px);
    min-height: rem-calc(271px);
    object-fit: contain;
    object-position: center center;
    width: 100%;
  }

  // Content Header
  &__content-header {
    font-size: rem-calc(21px);
    line-height: rem-calc(20px);
    margin: rem-calc(0px) rem-calc(0px) rem-calc(5px);
    font-family: $font-primary;    
  }

  // Content Body
  &__content-body > * {
    color: $color-medium;
    font-size: rem-calc(14px);
    font-weight: $font-weight-medium;
    line-height: rem-calc(21px);
    margin: rem-calc(0px);
  }

  // Desktop Breakpoint
  @media (min-width: map-get($breakpoints, "medium")) {

    padding: rem-calc(100px) rem-calc($gutter-desktop);

    &__title {
      line-height: rem-calc(42px);
      margin: rem-calc(0px) rem-calc(0px) rem-calc(100px);
    }

    &__content-wrapper {
      align-items: center;
      flex-direction: row;
      gap: rem-calc(40px);
      justify-content: space-between;
      margin: rem-calc(100px) rem-calc(0px) rem-calc(0px);

      &:first-child {
        margin: rem-calc(0px);
      }

      &.text-left {
        flex-direction: row-reverse;
      }
    }

    &__image {
      max-height: rem-calc(430px);
      min-height: rem-calc(250px);
      max-width: rem-calc(700px);
      min-width: rem-calc(300px);
    }

    &__text-wrapper {
      max-width: 48%;
    }

    &__content-header {
      font-size: rem-calc(32px);
      line-height: rem-calc(32px);
      margin: rem-calc(0px) rem-calc(0px) rem-calc(10px);
    }

    &__content-body > * {
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }
  }
}