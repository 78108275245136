@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "breadcrumbs";

.#{$object} {
    display: inline;
    list-style: none;
    margin: rem-calc(0);
    padding: rem-calc(0);

    &__wrapper {
        display: inline;
    }

    &__item {
        display: inline;
        margin-right: rem-calc(6px);
        @include extra-small-font;

        &:last-of-type {
            margin-right: rem-calc(0);
        }

        &__expand-button {
            border: none;            
            cursor: pointer;
        }

        &__icon {
            margin-left: rem-calc(6px);
            font-size: rem-calc(8px);
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        &__item {
            &__icon {
                font-size: rem-calc(9px);
            }
        }
    }
}
