@import "../../sass/variables";
@import "../../sass/mixins";

$object: "patent-card";

.#{$object} {
  font-family: $font-primary;
  background-color: $color-white;
  border: 1px solid $color-light;
  border-radius: 6px;
  box-sizing: border-box;
  padding: rem-calc(10px);

  &__wrapper {
    display: flex;
    margin-bottom: rem-calc(20px);
  }

  &__image-wrapper {
    height: fit-content;
    margin-right: rem-calc(10px);
    width: 40%;
    position: relative;

    &-custom {
      display: flex;
      justify-content: center;
    }
  }

  &__image {
    object-fit: cover;
    object-position: center center;
    aspect-ratio: 1;
    max-width: 100%;

    &-standard {
      height: 100%;
      width: 100%;
    }
  }

  &__product-info {
    display: flex;
    flex-direction: column;
    max-width: rem-calc(400px);
  }

  &__title {
    margin: 0 0 rem-calc(10px);
    line-height: rem-calc(19px);
    color: $color-dark;
  }

  &__description {
    font-size: rem-calc(12px);
    font-weight: $font-weight-medium;
    line-height: rem-calc(18px);
    margin: 0 0 rem-calc(10px);
    color: $color-medium;
    text-align: left;
  }

  &__btn {
    padding: rem-calc(0px) rem-calc(10px);
    width: 100%;
  }

  &__table {
    border: 0;
    padding: rem-calc(0);
    border-spacing: 0;
    margin-top: rem-calc(10px);
    width: 100%;
    font-size: rem-calc(12px);
    border-collapse: collapse;
    color: $color-dark;
  }

  &__table--header {
    background-color: $color-light;
  }

  &__table td,
  &__table th {
    padding: rem-calc(5px) rem-calc(20px);
    border: 1px solid $color-light;
    text-align: left;
    width: 50%;
  }

  &__table-left {
    background-color: $color-light-background;
  }

  &__view-more-less-cta {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    font-weight: $font-weight-extra-bold;
    text-decoration: none;
    width: max-content;
    margin-top: rem-calc(10px);
  }

  &__view-more-less-text {
    font-size: rem-calc(14px);
    margin: rem-calc(0px);
    text-decoration: underline;
    color: $color-dark;

    &:hover {
      text-decoration: none;
    }
  }

  &__button-icon {
    margin-left: rem-calc(10px);
    color: $color-dark;
  }

  &__note-wrapper {
    display: flex;
    gap: 5px;
    margin-bottom: rem-calc(20px);
  }
  &__note-icon {
    color: $color-primary;
  }

  &__note-title {
    color: $color-dark;
    margin-top: 0;
    margin-bottom: 0;
    @include small-font;
  }

  &__note-description {
    color: $color-medium;
    margin-top: rem-calc(5px);
    @include extra-small-font;
  }

  // Desktop Breakpoint
  @media (min-width: map-get($breakpoints, medium)) {
    padding: rem-calc(20px);

    &__wrapper {
      display: grid;
      grid-template-columns: 26% 1fr 25%;
      gap: 20px;
    }

    &__image-wrapper {
      margin-right: min(2vw, 30px);
      max-width: rem-calc(260px);
      min-width: rem-calc(160px);
      width: 100%;
    }

    &__product-info {
      margin-right: rem-calc($gutter-mobile);
    }

    &__cta {
      align-self: end;
    }

    &__note-wrapper {
      display: flex;
      gap: 5px;
      margin-bottom: 20px;
    }
    &__note-icon {
      color: $color-primary;
    }

    &__note-title {
      margin-top: 0;
      margin-bottom: 0;
      @include small-font;
    }

    &__note-description {
      margin-top: rem-calc(5px);
      @include extra-small-font;
    }

    &__view-details-cta {
      width: 100%;
    }
  }
}
