@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?g8bslo') format('truetype'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?g8bslo') format('woff'),
  url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?g8bslo##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="dart"],
[class*=" dart"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  display: inline-flex;
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.darticon-largearrow {
  &:before {
    content: $darticon-largearrow;
  }
}

.darticon-download {
  &:before {
    content: $darticon-download;
  }
}

.dartmadeinusa {
  &:before {
    content: $dartmadeinusa;
  }
}

.dartsourcereduced {
  &:before {
    content: $dartsourcereduced;
  }
}

.dartrenewablecontent {
  &:before {
    content: $dartrenewablecontent;
  }
}

.dartrecycle {
  &:before {
    content: $dartrecycle;
  }
}

.dartrecycledcontent {
  &:before {
    content: $dartrecycledcontent;
  }
}

.dartcommerciallycompostable {
  &:before {
    content: $dartcommerciallycompostable;
  }
}

.dartpaper {
  &:before {
    content: $dartpaper;
  }
}

.dartfiber {
  &:before {
    content: $dartfiber;
  }
}

.dartps {
  &:before {
    content: $dartps;
  }
}

.dartpm {
  &:before {
    content: $dartpm;
  }
}

.dartops {
  &:before {
    content: $dartops;
  }
}

.darthips {
  &:before {
    content: $darthips;
  }
}

.darteps {
  &:before {
    content: $darteps;
  }
}

.dartpp {
  &:before {
    content: $dartpp;
  }
}

.dartrpet {
  &:before {
    content: $dartrpet;
  }
}

.dartpete {
  &:before {
    content: $dartpete;

  }
}

.darticon-map {
  &:before {
    content: $darticon-map;
  }
}

.darticon-youtube {
  &:before {
    content: $darticon-youtube;
  }
}

.darticon-alert {
  &:before {
    content: $darticon-alert;
  }
}

.darticon-print {
  &:before {
    content: $darticon-print;
  }
}

.darticon-filter {
  &:before {
    content: $darticon-filter;
  }
}

.darticon-sort {
  &:before {
    content: $darticon-sort;
  }
}

.darticon-clock {
  &:before {
    content: $darticon-clock;
  }
}

.darticon-fax {
  &:before {
    content: $darticon-fax;
  }
}

.darticon-location {
  &:before {
    content: $darticon-location;
  }
}

.darticon-phone {
  &:before {
    content: $darticon-phone;
  }
}

.darticon-play {
  &:before {
    content: $darticon-play;
  }
}

.darticon-viewlist {
  &:before {
    content: $darticon-viewlist;
  }
}

.darticon-cart {
  &:before {
    content: $darticon-cart;
  }
}

.darticon-email {
  &:before {
    content: $darticon-email;
  }
}

.darticon-webpages {
  &:before {
    content: $darticon-webpages;
  }
}

.darticon-twitter {
  &:before {
    content: $darticon-twitter;
  }
}

.darticon-search {
  &:before {
    content: $darticon-search;
  }
}

.darticon-region {
  &:before {
    content: $darticon-region;
  }
}

.darticon-products {
  &:before {
    content: $darticon-products;
  }
}

.darticon-plus {
  &:before {
    content: $darticon-plus;
  }
}

.darticon-pinterest {
  &:before {
    content: $darticon-pinterest;
  }
}

.darticon-pdf {
  &:before {
    content: $darticon-pdf;
  }
}

.darticon-menu {
  &:before {
    content: $darticon-menu;
  }
}

.darticon-linkedin {
  &:before {
    content: $darticon-linkedin;
  }
}

.darticon-headersearch {
  &:before {
    content: $darticon-headersearch;
  }
}

.darticon-facebook {
  &:before {
    content: $darticon-facebook;
  }
}

.darticon-close {
  &:before {
    content: $darticon-close;
  }
}

.darticon-checkmark {
  &:before {
    content: $darticon-checkmark;
  }
}

.darticon-backtotop {
  &:before {
    content: $darticon-backtotop;
  }
}

.darticon-article {
  &:before {
    content: $darticon-article;
  }
}

.darticon-arrow {
  &:before {
    content: $darticon-arrow;
  }
}

.darticon-account {
  &:before {
    content: $darticon-account;
  }
}


/* manually created ones below */
.darticon-arrow-down {
  transform: rotate(90deg);

  &:before {
    content: $darticon-arrow;
  }
}

.darticon-arrow-up {
  transform: rotate(-90deg);

  &:before {
    content: $darticon-arrow;
  }
}

.darticon-arrow-back {
  transform: rotate(180deg);

  &:before {
    content: $darticon-arrow;
  }
}

.darticon-PFASfree {
  &:before {
    content: $darticon-PFASfree;
  }
}

.darticon-proPlanet_Leaf {
  &:before {
    content: $darticon-proPlanet_Leaf;
  }
}
