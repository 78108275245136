@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "detailed-authored-tile";

.#{$object} {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &--primary {
    border: 1px solid $color-light;
  }

  &__image {
    width: 100%;
    border-radius: 6px 6px 0 0;
    margin-left: auto;
    margin-right: auto;
    max-width: max-content;
  }

  &__heading {
    padding-top: rem-calc(20px);
    margin: rem-calc(0);
    font-size: rem-calc(16px);
    font-weight: $font-weight-medium;
    line-height: rem-calc(24px);
    color: $color-dark !important; //important to override global theme font color
    text-transform: capitalize;
  }

  &__description {
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    margin-block-start: 0.5em;

    & * {
      margin-block-start: rem-calc(0);
      color: $color-medium !important; //important to override global theme font color
      font-weight: $font-weight-medium !important; // Override global font size
    }
  }

  &--padding-side {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
  }

  &--border-radius {
    border-radius: 6px;
  }

  &--border-radius-bottom {
    border-radius: 0 0 6px 6px;
  }

  &--cta:hover {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__heading {
      font-size: rem-calc(21px);
      line-height: rem-calc(24px);
    }

    &--padding {
      padding: rem-calc(40px) rem-calc(40px) rem-calc(0);
    }
  }
}
