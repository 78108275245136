@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'example';

.#{$object} {
  color: $color-tertiary;

  &__header {
    background-color: purple;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary;
  }

  @media (min-width: map-get($breakpoints, large)) {
    color: $color-secondary;

    &__header {
      background-color: red;
    }
  }
}