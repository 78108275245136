@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'single-type-search';

.#{$object} {

    font-family: $font-primary;
    margin-inline: auto;
    max-width: 100%;

    // Wrapper
    &__wrapper {
        background-color: $color-white;
        border: 1px solid $color-light;
        border-radius: 6px;
        box-sizing: border-box;
        display: flex;
        gap: rem-calc($gutter-mobile);
        padding: rem-calc(10px);

        &.document {
            flex-direction: column;
            padding: rem-calc($gutter-mobile);
        }
    }

    // Image Container
    &__image-container {
        aspect-ratio: 1;
        box-sizing: border-box;
        height: rem-calc(100px);
        width: rem-calc(100px);

        &.document {
            align-self: center;
            aspect-ratio: 1.28;
            height: 100%;
            max-height: rem-calc(165px);
            overflow: hidden;
            width: 100%;
        }
    }

    &__image {
        aspect-ratio: 1.7;
        box-sizing: border-box;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        width: 100%;

        &.document {
            aspect-ratio: 1.28;
            object-fit: contain;
        }
    }

    // Content Container
    &__content-container {
        display: flex;
        flex-direction: column;
        gap: rem-calc(20px);
        width: 100%;
    }

    // Info Container
    &__info-container>* {
        font-family: $font-primary;
        margin: rem-calc(0px);
    }

    // Category & Date
    &__category-date {
        color: $color-primary;
        font-size: rem-calc(12px);
        font-weight: $font-weight-bold;
        line-height: rem-calc(16px);
        margin-bottom: rem-calc(5px);
    }

    // Category & Date Separator
    &__separator {
        margin-inline: rem-calc(5px);
    }

    // Header
    &__header {
        color: $color-dark;
        font-size: rem-calc(14px);
        font-weight: $font-weight-extra-bold;
        line-height: rem-calc(19px);
        font-family: $font-primary;
        max-width: 225px;

        &.document {
            font-size: rem-calc(16px);
            line-height: rem-calc(22px);
        }
    }

    // Description
    &__description>* {
        color: $color-medium;
        font-size: rem-calc(12px);
        font-weight: $font-weight-medium;
        line-height: rem-calc(16px);
        margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
    }

    // CTA
    &__cta {
        max-width: rem-calc(200px);
        padding: rem-calc(0px) rem-calc(10px) !important;
        width: 100%;

        &.document {
            max-width: 100%;
        }
    }

    // Desktop Breakpoint
    @media (min-width: map-get($breakpoints, medium)) {
        &__wrapper {
            padding: rem-calc($gutter-mobile);

            &.document {
                gap: rem-calc(32px);
            }
        }

        &__image-container {

            &.article,
            &.webpage,
            &.media {
                aspect-ratio: 1.7;
                height: rem-calc(130px);
                width: rem-calc(220px);
            }

            &.pdf {
                aspect-ratio: 1.8;
                height: rem-calc(100px);
                width: rem-calc(180px);
            }

            &.document {
                max-height: rem-calc(177px);
            }
        }

        &__image {
            &.pdf {
                object-fit: contain;
            }
        }

        &__content-container {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
            gap: rem-calc(40px);

            &.document {
                align-items: flex-start;
                flex-direction: column;
                gap: rem-calc(30px);
            }
        }

        &__info-container {

            &.article,
            &.media {
                align-self: flex-end;
                height: calc(100% - 15px);
            }

            &.webpage,
            &.pdf {
                align-self: flex-start;
            }
        }

        &__category-date {
            font-size: rem-calc(14px);
            line-height: rem-calc(19px);
            margin-bottom: rem-calc(10px);
        }

        &__header {
            font-size: rem-calc(21px);
            line-height: rem-calc(24px);
        }

        &__description>* {
            font-size: rem-calc(14px);
            line-height: rem-calc(19px);
            margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
        }
    }
}