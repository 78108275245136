@use "../../sass/variables"as *;
@use "../../sass/mixins"as *;

$object: "no-results";

@mixin font-style-mobile {
    font-family: $font-secondary;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    margin: 0;
    text-transform: uppercase;
}

@mixin font-style-desktop {
    font-size: rem-calc(21px);
    margin-bottom: rem-calc(20px);
}

.#{$object} {
    padding: rem-calc(30px) rem-calc($gutter-mobile) rem-calc(60px);
    max-width: $width-max;
    margin: 0 auto;

    &__term {
        font-weight: $font-weight-semi-bold;
        color: $color-black;
    }

    &__header {
        &__title {
            font-size: rem-calc(28px);
            line-height: rem-calc(27px);
            margin: 0 0 rem-calc(5px);
            font-family: $font-primary;
        }

        &__caption {
            margin: 0;
            color: $color-medium;

            &__search-value {
                text-transform: capitalize;
                color: $color-dark;
            }
        }
    }

    &__aid {
        &__suggestions {
            margin-top: rem-calc(40px);

            &__title {
                @include font-style-mobile;
                margin-bottom: rem-calc(10px);
            }

            &__list {
                @include reset-list;

                &__item {
                    color: $color-dark;
                    margin-top: rem-calc(15px);

                    &:first-of-type {
                        margin: 0;
                    }
                }
            }
        }

        &__guide {
            margin-top: rem-calc(40px);

            &__title {
                @include font-style-mobile;
                margin-bottom: rem-calc(10px);
            }

            &__caption {
                @include small-font;
                margin: 0;
                color: $color-medium;

                &>p {
                    margin: 0;
                    color: inherit;
                }
            }
        }
    }

    &__popular-products {
        margin-top: rem-calc(40px);

        &__title {
            @include font-style-mobile;
            margin-bottom: rem-calc(20px);
        }

        &__grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: rem-calc(15px);
        }
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        padding: rem-calc(75px) rem-calc($gutter-desktop) rem-calc(100px);

        &__header {
            &__title {
                font-size: rem-calc(48px);
                line-height: rem-calc(47px);
                margin-bottom: rem-calc(10px);
            }

            &__caption {
                font-size: rem-calc(21px);
                line-height: rem-calc(29px);
            }
        }

        &__aid {
            display: flex;
            margin-top: rem-calc(60px);

            &__suggestions {
                flex: 33.33% 0 0;
                margin: 0;

                &__title {
                    @include font-style-desktop;
                }
            }

            &__guide {
                flex: 66.67% 0 0;
                margin: 0;

                &__title {
                    @include font-style-desktop;
                }

                &__caption {
                    font-size: inherit;
                    line-height: inherit;
                }
            }
        }

        &__popular-products {
            margin-top: rem-calc(60px);

            &__title {
                @include font-style-desktop;
            }

            &__grid {
                grid-template-columns: repeat(4, 1fr);
                gap: rem-calc(40px);
            }
        }
    }
}