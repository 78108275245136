@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "scroll-top";

.#{$object} {
  position: fixed;
  right: rem-calc(5px);
  bottom: rem-calc(20px);
  width: rem-calc(45px);
  height: rem-calc(45px);
  background: $color-white;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.5s ease-in-out;
  z-index: 10;

  &--hidden {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &__button {
    width: 100%;
    height: 100%;
    font-size: rem-calc(10px);
    border: 0;
    background-color: transparent;
    padding: rem-calc(0);
    border-radius: 6px;
    cursor: pointer;

    &__icon {
      display: block;
      margin: rem-calc(0) auto;
      width: rem-calc(20px);
      height: rem-calc(10px);
    }

    &__label {
      display: none;
    }

    &:focus,
    &:focus-visible,
    &:active {
      border: 2px solid $color-dark;
      outline: 2px solid $color-dark;
    }
  }

  @media (min-width: map-get($breakpoints, medium )) {
    width: rem-calc(50px);
    height: rem-calc(60px);

    &__button {
      &__icon {
        margin: rem-calc(0) auto rem-calc(8px) auto;
      }

      &__label {
        color: $color-dark;
        display: inline;
        line-height: rem-calc(11px);
      }
    }
  }

  @media(max-width: map-get($breakpoints, medium)) {
    right: rem-calc(5px);
  }
}
