@import "../../sass/variables";
@import "../../sass/mixins";

$object: "video-container";

.#{$object} {
  position: relative;

  &__video {
    max-height: 90%;
    max-width: 100%;
    clip-path: inset(1px 1px); //this removes a weird artifact on responsive html5 videos when the size doesn't perfectly match the aspect ratio
  }

  &__control-group.inline-block {
    align-items: self-end;
    display: flex;
  }

  &__control-group.overlay {
    position: absolute;
    z-index: 1;
    left: 10px;
    bottom: 10px;
  }

  &__control-button {
    padding: 5px;
    min-width: 80px;
    height: 48px;
    float: right;
    box-sizing: border-box;
    font-weight: $font-weight-bold;
    color: $color-primary;
    background-color: transparent;
    border: 2px solid transparent;
    outline: none;
    cursor: pointer;
    z-index: 3; //in case the button is stuck behind other content - for accessibility this must always be visible and clickable

    &.overlay {
      background-color: rgba($color-white, 0.75);
    }

    &:hover {
      font-weight: $font-weight-bold;
      text-decoration: underline;
      color: $color-primary-light;
      border: 2px solid $color-primary-light;
    }

    &--secondary {
      color: $color-primary;
    }

    &--tertiary {
      color: $color-white;
    }
  }

  @media (max-width: map-get($breakpoints, medium-small)) {
    position: relative;
  }
}