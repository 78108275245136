@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "header-toolbar";

.#{$object} {
    padding: rem-calc(0) rem-calc($gutter-mobile);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: baseline;
    margin: rem-calc(10px) auto;
    max-width: rem-calc($width-max);

    .breadcrumbs__wrapper {
        margin-bottom: rem-calc(5px);
    }

    &--breadcrumbs-condensed {
        flex-direction: row;
        gap: rem-calc(5px);

        .breadcrumbs__wrapper {
            margin-bottom: rem-calc(0);
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        flex-direction: row;
        margin: rem-calc(15px) auto;

        .breadcrumbs__wrapper {
            margin-bottom: rem-calc(0);
        }
    }

    @media (min-width: map-get($breakpoints, "large")) {
        padding: rem-calc(0) rem-calc($gutter-desktop);
    }
}
