@import "./variables";
@import "./normalize";
@import "./fonts";
@import "./mixins";

:root {
  --color-primary: #0054a6;
  --color-primary-dark: #034076;
  --color-primary-light: #2f75c8;
  //need both the color sets we could possibly have set in JS
  --color-primary-mexico: #2a624b;
  --color-primary-mexico-dark: #0D331F;
  --color-primary-mexico-light: #9FB4A9;
  --color-primary-regular: #0054a6;
  --color-primary-regular-dark: #034076;
  --color-primary-regular-light: #2f75c8;
}

html,
body {
  color: $color-text-default;
  font-family: $font-primary;
  font-size: rem-calc($font-default-size);
  line-height: rem-calc(22px);
}

.content-bound {
  max-width: rem-calc($width-max);
  margin: 0 auto;
}

h1 {
  font-size: rem-calc(32px);
  line-height: rem-calc(30px);
  color: $color-primary;
  font-family: $font-secondary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

h2 {
  font-size: rem-calc(28px);
  line-height: rem-calc(26px);
  color: $color-primary;
  font-family: $font-secondary;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

h3 {
  font-size: rem-calc(24px);
  line-height: rem-calc(23px);
  color: $color-primary;
  font-family: $font-primary;
  font-weight: $font-weight-bold;
}

h4 {
  font-size: rem-calc(18px);
  line-height: rem-calc(17px);
  color: $color-primary;
  font-family: $font-primary;
  font-weight: $font-weight-bold;
}

h5 {
  font-size: rem-calc(14px);
  line-height: rem-calc(19px);
  color: $color-medium;
  font-family: $font-primary;
  font-weight: $font-weight-extra-bold;
}

h6 {
  font-size: rem-calc(16px);
  line-height: rem-calc(22px);
  color: $color-medium;
  font-family: $font-primary;
  font-weight: $font-weight-extra-bold;
}

p {
  color: $color-medium;

  @include medium-font;

  &.small {
    @include small-font;
  }
}

a {
  color: $color-dark;
  text-decoration: underline;
  font-weight: $font-weight-extra-bold;

  &:hover {
    text-decoration: none;
  }
}

// button:focus-visible {
//   outline: 1px solid ButtonText;
// }

.btn {
  height: rem-calc(40px);
  padding: 0 rem-calc(41.5px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 6px;
  color: $color-dark;
  font-size: rem-calc(14px);
  line-height: rem-calc(19px);
  font-family: $font-primary;
  font-weight: $font-weight-extra-bold;
  text-decoration: none;
  box-sizing: border-box;


  &:hover, &:focus{
    border-color: $color-secondary-light;
    background: $color-secondary-light;
    cursor: pointer;
  }

  &--secondary {
    border-color: $color-dark;
    background: $color-white;

    &:hover, &:focus{
      color: $color-white;
      border-color: $color-medium;
      background: $color-medium;
    }
  }

  &--tertiary {
    border-color: $color-white;
    background: $color-white;

    &:hover, &:focus{
      color: $color-white;
      border-color: $color-medium;
      background: $color-medium;
    }
  }

  &--link {
    border-color: $color-white;
    background: $color-white;
    text-decoration: underline;

    &:hover, &:focus{
      border-color: $color-white;
      background: transparent;
      text-decoration: none;
    }
  }

  &--play-video {
    padding: 0 rem-calc(24px);
  }
}

.radio-checkbox-wrapper *,
.radio-checkbox-wrapper *::before,
.radio-checkbox-wrapper *::after {
  box-sizing: content-box !important;
}

.radio-checkbox-wrapper input {
  width: 20px;
  height: 20px;
  position: absolute;
  opacity:0;
  z-index:1;
  cursor: pointer;
}

.radio-checkbox-contain:focus-within {
  outline: solid black 2px;
  outline-offset: 10px;
}

.radio-checkbox-contain label {
  line-height: rem-calc(19px);
  font-size: rem-calc(14px);
  font-family: inherit;
  padding-left: 10px;
}

.radio-checkbox-contain {
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 2rem;
  flex-direction: row-reverse;
}

.radio-checkbox-wrapper input[type="checkbox"]~.radio-checkbox-input {
  display: block;
  position: relative;
  align-self: center;
  height: 18px;
  width: 18px;
  background: $color-white;
  transition: background 250ms;
  border: 1px solid $color-light;
  border-radius: 3px;
}

.radio-checkbox-wrapper input[type="radio"]~.radio-checkbox-input {
  display: block;
  position: relative;
  align-self: center;
  height: 18px;
  width: 18px;
  background: $color-white;
  transition: background 250ms;
  border: 1px solid $color-light;
  border-radius: 16px;
  z-index: -1;
}

.radio-checkbox-wrapper input[type="checkbox"]~.radio-checkbox-input::after {
  content: "";
  position: absolute;
  opacity: 0;
  left: 6px;
  top: 1px;
  width: 4px;
  height: 9px;
  border: solid $color-white;
  border-width: 0 3px 3px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.radio-checkbox-wrapper input[type="radio"]~.radio-checkbox-input::after {
  content: "";
  position: absolute;
  opacity: 0;
  left: 6px;
  top: 6px;
  width: 6px;
  height: 6px;
  border-radius: 16px;
  background: $color-white;
  transition: background 250ms;
}

.radio-checkbox-wrapper input:disabled~.radio-checkbox-input::after {
  border-color: #8795a1;
}

.radio-checkbox-wrapper input:checked~.radio-checkbox-input::after {
  opacity: 1;
}

.radio-checkbox-wrapper:hover input~.radio-checkbox-input,
.radio-checkbox-wrapper input:focus~.radio-checkbox-input {
  background: $color-white;
}

.radio-checkbox-wrapper input:hover~.radio-checkbox-input {
  border-color: $color-medium;
}

.radio-checkbox-wrapper input:checked~.radio-checkbox-input {
  background: $color-primary;
  border-color: $color-primary;
}

.radio-checkbox-wrapper input[type="checkbox"]:disabled~.radio-checkbox-input {
  background: #f1f5f8;
  border-color: #b8c2cc;
  opacity: 0.6;
  cursor: not-allowed;
}

.radio-checkbox-wrapper input[type="radio"]:disabled~.radio-checkbox-input {
  background: #f1f5f8;
  border-color: #b8c2cc;
  opacity: 0.6;
  cursor: not-allowed;
}

.radio-checkbox-wrapper input[type="radio"]:disabled~.radio-checkbox-input::after {
  background: #8795a1;
}

.radio-checkbox-wrapper input:checked~.radio-checkbox-input,
.radio-checkbox-wrapper:hover input:not([disabled]):checked~.radio-checkbox-input {
  background: $color-primary;
  border-color: $color-medium;
}

.radio-checkbox-wrapper .radio-checkbox-input::before {
  content: "";
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.85rem;
  margin-top: -0.85rem;
  background: $color-primary;
  border-radius: 2rem;
  opacity: 0.6;
  z-index: 99999;
  transform: scale(0);
}

label {
  display: block;
  color: $color-dark;
  font-weight: $font-weight-extra-bold;
  font-size: rem-calc(14px);
  line-height: rem-calc(18px);
  margin-bottom: rem-calc(5px);
}

// input[type="text"],
// input[type="datetime-local"],
// input[type="email"],
// input[type="file"],
// input[type="image"],
// input[type="month"],
// input[type="number"],
// input[type="password"],
// input[type="range"],
// input[type="search"],
// input[type="tel"],
// input[type="time"],
// input[type="url"],
// input[type="week"]

input {
  border: 1px solid $color-light;
  border-radius: 6px;
  color: $color-dark;
  display: flex;
  align-items: center;
  height: rem-calc(40px);
  font-weight: $font-weight-medium;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  padding-left: rem-calc(20px);

  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::placeholder,
  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &:focus,
  &:active,
  &:focus-visible {
    border-color: $color-medium;
    outline: none;
  }

  &.error {
    border-color: $color-error;

    &:focus,
    &:active {
      border-color: $color-error;
    }
  }
}

li:has(input[type="radio"]) {
  &:focus-within {
    border-color: $color-medium;
    outline: 2px solid $color-medium;
  }
}

// Safari causing problems
input:is([type="button"], [type="submit"], [type="reset"]),
input[type="file"]::file-selector-button,
button {
  color: $color-dark; // Changes blue color to #333
  padding: 0px; // Removing top bottom padding
}

// iOS 14.1
input:matches([type="button"], [type="submit"], [type="reset"]),
input[type="file"]::-webkit-file-upload-button,
button {
  padding: 0px;
}

input:is([type="button"], [type="submit"], [type="reset"]) {
  padding: 0 rem-calc(20px);
}

textarea {
  border: 1px solid $color-light;
  border-radius: 6px;
  color: $color-dark;
  font-weight: $font-weight-medium;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  padding-left: rem-calc(20px);
  padding-top: rem-calc(10px);

  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::placeholder,
  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &:focus,
  &:active,
  &:focus-visible {
    border-color: $color-medium;
    outline: 2px solid $color-medium;
  }

  &.error {
    border-color: $color-error;

    &:focus,
    &:active {
      border-color: $color-error;
    }
  }
}

select {
  border: 1px solid $color-light;
  border-radius: 6px;
  color: $color-dark;
  display: flex;
  align-items: center;
  height: rem-calc(40px);
  font-weight: $font-weight-medium;
  font-size: rem-calc(14px);
  line-height: rem-calc(20px);
  padding: 0 rem-calc(20px);

  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &::placeholder,
  &::-ms-input-placeholder,
  &:-ms-input-placeholder {
    color: $color-medium;
    font-weight: $font-weight-normal;
  }

  &:focus,
  &:active,
  &:focus-visible {
    border-color: $color-medium;
    outline: none;
  }

  &.error {
    border-color: $color-error;

    &:focus,
    &:active {
      border-color: $color-error;
    }
  }
}

.form-embed input {
  width: 100%;
  box-sizing: border-box;
}

.form-embed input[type="submit"] {
  width: auto;
  height: rem-calc(40px);
  padding: 0 rem-calc(41.5px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $color-secondary;
  border: 2px solid $color-secondary;
  border-radius: 6px;
  color: $color-dark;
  font-size: rem-calc(14px);
  line-height: rem-calc(19px);
  font-family: $font-primary;
  font-weight: $font-weight-extra-bold;
  text-decoration: none;
  box-sizing: border-box;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible {
    border-color: $color-secondary-light;
    background: $color-secondary-light;
    cursor: pointer;
  }

  &:focus,
  &:active,
  &:focus-visible {
    border-color: $color-dark;
    outline: 1px solid auto;
  }
}

.form-embed li {
  display: flex;
  align-items: center;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;

  label {
    margin: 0;
  }
}

.error-message {
  display: block;
  color: $color-error;
  margin-top: rem-calc(5px);
  font-size: rem-calc(14px);
  line-height: rem-calc(24px);
  font-weight: $font-weight-semi-bold;
}

.bold-normal {
  font-weight: $font-weight-normal;
}

.bold-medium {
  font-weight: $font-weight-medium;
}

.bold-semi-bold {
  font-weight: $font-weight-semi-bold;
}

.bold-bold {
  font-weight: $font-weight-bold;
}

.bold-extra-bold {
  font-weight: $font-weight-extra-bold;
}

.primary-font {
  font-family: $font-primary;
}

.secondary-font {
  font-family: $font-secondary;
}

.theme {
  &-primary {
    background-color: $color-white;
  }

  &-secondary {
    background-color: $color-light;
  }

  &-tertiary {
    background-color: $color-primary;
    color: $color-white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color-white;
    }
  }
}

.info-icon {
  width: rem-calc(16px);
  height: rem-calc(16px);
  border-radius: rem-calc(8px);
  font-family: $font-secondary;
  font-weight: $font-weight-bold;
  font-size: rem-calc(12px);
  line-height: rem-calc(12px);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $color-dark;
  background-color: $color-light;
  margin-left: 5px;

  &::after {
    content: 'i';
  }

  &:hover {
    background-color: $color-medium;
  }
}

//tooltip css
.__react_component_tooltip {
  background-color: $color-light-background !important;
  box-sizing: border-box;
  left: rem-calc(0px) !important;
  position: absolute !important;
  top: 50% !important;
  width: 100% !important;

  &.show {
    opacity: 1 !important;
  }

  &.place-top {
    &::after {
      border-top-color: $color-light-background !important;
    }
  }

  &.place-bottom {
    &::after {
      border-bottom-color: $color-light-background !important;
    }
  }

  &.place-right {
    &::after {
      border-right-color: $color-light-background !important;
    }
  }

  &.place-left {
    &::after {
      border-left-color: $color-light-background !important;
    }
  }
}

.tooltip__title {
  font-weight: $font-weight-bold;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);
  color: $color-dark;
  display: block;
  width: 100%;
  margin-bottom: rem-calc(1px);
}

.tooltip__text {
  font-weight: $font-weight-medium;
  font-size: rem-calc(12px);
  line-height: rem-calc(16px);
  color: $color-medium;
  display: block;
  width: 100%;
}

//map popups
//remove this if you want popups to come back
.leaflet-popup {
  display: none;
}

// allows for equal height cards
.swiper {
  height: 100% !important;

  //swiper carousel
  .swiper-button-prev,
  .swiper-button-next {
    color: $color-dark;
    font-size: rem-calc(10px);
    font-weight: $font-weight-extra-bold;
  }

  .swiper-button-disabled {
    display: none;
  }

  .swiper-button-next {
    right: rem-calc(5px);
    padding: 15px;
    border: 2px solid transparent;
  }

  .swiper-button-prev {
    left: rem-calc(5px);
    padding: 15px;
    border: 2px solid transparent;
  }
}

.hide-arrows {
  .swiper {

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}

.lds-default {
  display: flex;
  position: relative;
  z-index: 16;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  top: 10%;
  transform: translateY(-10%);
  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: $color-white;
    border-radius: 50%;
    -webkit-animation: lds-default 1.2s linear infinite;
            animation: lds-default 1.2s linear infinite;
  }
}

.lds-blue {
  div{
    background:$color-primary;
  }
}

// .lds-default div {
//   position: absolute;
//   width: 6px;
//   height: 6px;
//   background: $color-white;
//   border-radius: 50%;
//   animation: lds-default 1.2s linear infinite;
// }

.lds-default div:nth-child(1) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@-webkit-keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@keyframes lds-default {

  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

@media (min-width: map-get($breakpoints, medium)) {
  h1 {
    font-size: rem-calc(48px);
    line-height: rem-calc(46px);
  }

  h2 {
    font-size: rem-calc(42px);
    line-height: rem-calc(40px);
  }

  h3 {
    font-size: rem-calc(32px);
    line-height: rem-calc(30px);
  }

  h4 {
    font-size: rem-calc(21px);
    line-height: rem-calc(22px);
  }

  h5 {
    font-size: rem-calc(21px);
    line-height: rem-calc(24px);
  }

  h6 {
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
  }

  .btn {
    height: rem-calc(50px);

    &--play-video {
      padding: 0 rem-calc(31px);
    }
  }

  .form-embed input[type="submit"] {
    height: rem-calc(50px);
  }

  .radio-checkbox-wrapper input[type="checkbox"]~.radio-checkbox-input,
  .radio-checkbox-wrapper input[type="checkbox"]~.radio-checkbox-input {
    height: 20px;
    width: 20px;
  }

  .radio-checkbox-wrapper input[type="radio"]~.radio-checkbox-input {
    height: 20px;
    width: 20px;
  }

  .radio-checkbox-wrapper input[type="checkbox"]~.radio-checkbox-input::after {
    left: 6px;
    top: 1px;
    width: 5px;
    height: 10px;
  }

  .radio-checkbox-wrapper input[type="radio"]~.radio-checkbox-input::after {
    left: 6px;
    top: 6px;
    width: 8px;
    height: 8px;
  }

  .radio-checkbox-contain label {
    font-size: rem-calc(16px);
  }

  input,
  select {
    height: rem-calc(50px);
  }

  input,
  select,
  textarea {
    font-size: rem-calc(16px);
    line-height: rem-calc(20px);
  }
}

@media (min-width: map-get($breakpoints, large)) {}

@media (min-width: map-get($breakpoints, xlarge)) {
  .swiper-button-next,
  .swiper-button-prev {
    &:hover {
      border: 2px solid $color-dark;
      border-radius: 5px;
      cursor:pointer;
    }
  }
}

// When printing in Saved Products
// Don't want cookie banner showing up and blocking products
@media print {
  .optanon-alert-box-wrapper {
    display: none;
  }
}