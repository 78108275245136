@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'complex-flyout';

.#{$object} {
  position: relative;

  &__header-wrapper {
    border-bottom: 1px solid $color-light;
    padding: rem-calc(30px) rem-calc($gutter-mobile);
  }

  &__back-btn {
    border: none;
    background: transparent;
    font-size: rem-calc(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    color: $color-dark;
  }

  &__header-line-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__header {
    font-size: rem-calc(21px);
    line-height: rem-calc(20px);
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-transform: uppercase;
  }

  &__content-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: rem-calc(0) rem-calc(0) rem-calc(31px);
  }

  &__list {
    list-style-type: none;
    margin: rem-calc(0);
    padding: rem-calc(10px) rem-calc($gutter-mobile) rem-calc(29px);
    width: calc(100% - #{$gutter-mobile * 2});
  }

  &__list-item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 2.5rem;
    margin: 1rem 0;
  }

  &__list-item-link {
    cursor: pointer;
    color: $color-dark;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 2.5rem;
    height: 100%;
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(16px);
    line-height: rem-calc(20px);
    text-decoration: none;
    background-color: transparent;
    border: none;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }
  }

  &__list-item-link-icon {
    color: $color-dark;
    font-size: rem-calc(12px);
    display: flex;
    align-items: center;
  }

  &__articles-popular-wrapper {
    display: none;
    flex-direction: column;
    width: 100%;
    padding: rem-calc(0) rem-calc($gutter-mobile);
    box-sizing: border-box;
  }

  &__articles-popular-items-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: rem-calc(0);
    box-sizing: border-box;
    gap: 10px;
  }

  &__articles-popular-header {
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    color: $color-primary;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: rem-calc(17px);
    margin-top: 0;
  }

  &__article {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    border: none;
    overflow: hidden;
    margin-bottom: rem-calc(20px);
    text-decoration: none;

    &:last-child {
      margin-bottom: rem-calc(0);
    }

    &:hover {
      &.cta {
        box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.05);
      }
    }
  }

  &__article-info {
    display: flex;
    flex-direction: column;
    padding-top: rem-calc(15px);
  }

  &__article-image {
    width: rem-calc(100px);
    height: rem-calc(100px);
    text-align: center;
    border: 1px solid $color-light;
    border-radius: 6px;
    margin-bottom: rem-calc(0);
    object-fit: cover;
    object-position: center center;
  }

  &__article-title {
    display: block;
    padding: rem-calc(0) rem-calc(15px);
    margin-bottom: rem-calc(2px);
    font-weight: $font-weight-extra-bold;
    color: $color-dark;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    text-decoration: none;
  }

  &__article-description {
    font-weight: $font-weight-medium;
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    color: $color-dark;
    padding: rem-calc(0) rem-calc(15px);
    margin: rem-calc(0);
  }

  &__article-description>* {
    font-weight: $font-weight-medium;
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    color: $color-medium !important;
    margin: rem-calc(0px);
  }

  &__sub-nav-wrapper {
    position: fixed;
    top: calc($topBarHeightMobile + 1px); //need extra pixel for border
    left: 0;
    background-color: $color-white;
    width: 100%;
    height: calc(100vh - #{$topBarHeightMobile  + 1px});
  }

  &__view-all-link-wrapper {
    display: none;
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    &__header-wrapper {
      display: none;
    }

    &__list {
      width: 100%;
    }

    &__list-wrapper {
      box-sizing: border-box;
      width: 33.3%;
      border-right: 1px solid $color-light;
    }

    &__list {
      padding: rem-calc(31px) rem-calc(0);
      box-sizing: border-box;
    }

    &__content-wrapper {
      max-width: rem-calc($width-max);
      margin: rem-calc(0) auto;
      width: 100%;
      height: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      padding: rem-calc(0) rem-calc($gutter-desktop);
    }

    &__articles-popular-wrapper {
      width: 66.6%;
      box-sizing: border-box;
      padding: 1rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 0 2%;
    }

    &__articles-popular-items-wrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 1%;
      .navigation-product-tile {
        width: 48%;
      }
      &--full {
        .navigation-product-tile {
          width: 30%;
        }
      }
    }


    &__article-info {
      padding-top: rem-calc(0);
    }

    &__articles-popular-header {
      font-family: $font-secondary;
      font-weight: $font-weight-bold;
      font-size: rem-calc(21px);
      line-height: rem-calc(24px);
      color: $color-primary;
      width: 100%;
      text-transform: uppercase;
      margin-bottom: rem-calc(18px);
    }

    &__article {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      width: 31%;
      max-width: rem-calc(260px);
      border: 1px solid $color-light;
      border-radius: 6px;
      overflow: hidden;
      margin: rem-calc(0);
    }

    &__article-image {
      max-width: 100%;
      width: 100%;
      height: rem-calc(140px);
      text-align: center;
      border: none;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin-bottom: rem-calc(19px);
    }

    &__article-title {
      display: block;
      padding: rem-calc(0) rem-calc(20px);
      margin-bottom: rem-calc(10px);
      font-weight: $font-weight-extra-bold;
      font-size: rem-calc(16px);
      line-height: rem-calc(20px);
      text-decoration: none;
    }

    &__article-description {
      font-weight: $font-weight-medium;
      font-size: rem-calc(12px);
      line-height: rem-calc(16px);
      color: $color-dark;
      padding: rem-calc(0) rem-calc(20px);
      margin: rem-calc(0) rem-calc(0) rem-calc(30px) rem-calc(0);
    }

    &__article-description>* {
      font-weight: $font-weight-medium;
      font-size: rem-calc(12px);
      line-height: rem-calc(16px);
      color: $color-dark;
    }

    &__list-item {
    margin: 1rem 0;
      &:hover,
      &.active {
        background-color: $color-primary;
        color: $color-white;

        .#{$object}__list-item-link {
          color: $color-white;

          &:hover {
            text-decoration: none;
          }
        }

        .#{$object}__list-item-link-icon {
          color: $color-white;
        }
      }
    }

    &__list-item-link {
      padding: 0 1rem;
      min-height: 2.5rem;
    }

    &__sub-nav-wrapper {
      position: static;
      background-color: $color-white;
      height: auto;
      width: 66.6%;
      box-sizing: border-box;
      padding: rem-calc(0);

      .#{$object}__content-wrapper {
        padding: rem-calc(0);
      }

      .#{$object}__list-wrapper {
        width: 32%;
        max-width: rem-calc(296px);
      }

      .#{$object}__list-item-link {
        padding: 0 1rem;
      }

      .#{$object}__articles-popular-wrapper {
        //need to subtrack the padding on each side
        width: calc(68% - 120px);
      }

      .#{$object}__article {
        width: 49%;
      }
    }

    &__view-all-link-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: $color-primary;
      color: $color-white;
    }

    &__view-all-link {
      color: $color-white;
      font-weight: $font-weight-extra-bold;
      font-size: rem-calc(16px);
      line-height: rem-calc(40px);
      margin: 0 auto;
      padding: 10px 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      //this is the icon
      span {
        margin-left: 10px;
        font-size: rem-calc(12px);
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: map-get($breakpoints, xxlarge)) {
    &__list {
      padding: rem-calc(31px) rem-calc(0);
    }
  }
}