@use "../../sass/variables" as *;
@use "../../sass/mixins" as *;

$object: "hero-banner";

.#{$object} {
    &--secondary,
    &--tertiary {
        .#{$object}__left {
            padding: rem-calc(40px) rem-calc($gutter-mobile) rem-calc(55px);
        }

        .#{$object}__heading {
            font-size: rem-calc(28px);
            line-height: rem-calc(26px);
        }
    }

    &__left {
        padding: rem-calc(44px) rem-calc($gutter-mobile);
        display: flex;

        &--bg-white {
            background-color: $text-background-white;
        }
        &--bg-black {
            background-color: $text-background-black;
        }
        &--bg-blue {
            background-color: $text-background-blue;
        }
        &--bg-green {
            background-color: $text-background-green;
        }
    }

    &__text-wrapper {
        align-self: center;
    }

    &__heading {
        margin: rem-calc(0);
        font-size: rem-calc(32px);
        line-height: rem-calc(30px);
        color: $color-primary;        
        font-weight: $font-weight-bold;        

        &-wrapper {
            & + .#{$object}__description {
                margin: rem-calc(20px) rem-calc(0) rem-calc(0) rem-calc(0);
            }
        }
    }

    &__description,
    &__description p {
        @include small-font;
    }

    &__description p {
        margin: rem-calc(0);
    }

    &__cta {
        margin: rem-calc(30px) rem-calc(0) rem-calc(0) rem-calc(0);
    }

    &__image {
        display: block;
        margin: 0 auto;
        height: auto;

        &-normal {
            width: 100%;
            max-width: 100%;
        }

        &-custom {
            margin-right: 0;
        }
    }

    @media (min-width: map-get($breakpoints, medium)) {
        &__outer {
            box-sizing: border-box;
            max-width: 100%;
            padding: rem-calc(0) rem-calc($gutter-desktop);

            &--with-image {
                background-repeat: no-repeat;
                background-position: 65%;
            }
        }

        &__inner {
            box-sizing: border-box;
            margin: rem-calc(0) auto;
            max-width: rem-calc($width-max);
            min-height: rem-calc(600px);
            display: flex;
        }

        &--secondary,
        &--tertiary {
            .#{$object}__inner {
                min-height: rem-calc(645px);
            }

            .#{$object}__heading {
                font-size: rem-calc(42px);
                line-height: rem-calc(40px);
            }
        }

        &__heading {
            font-size: rem-calc(48px);
            line-height: rem-calc(46px); 
        }

        &--text-left {
            .#{$object}__inner {
                justify-content: flex-start;
            }
        }

        &--text-center{
            .#{$object}__inner {
                justify-content: center;
            }
        }

        &--text-right {
            .#{$object}__inner {
                justify-content: flex-end;
            }
        }

        &__left {
            width: 50%;
        }

        &__heading {
            &-wrapper {
                max-width: rem-calc(504px);
            }
        }

        &__description {
            max-width: rem-calc(405px);
        }

        &__description,
        &__description p {
            @include medium-font;
        }

        &__cta {
            margin: rem-calc(40px) rem-calc(0) rem-calc(0) rem-calc(0);
        }
    }

    @media (min-width: map-get($breakpoints, xlarge)) {
        &__left {
            width: 33.33%;
        }
    }
}


.#{$object}.theme-tertiary{
    .#{$object}__description a{
        color: $color-white;
        // text-decoration: none;
        font-weight: normal;
        &:hover{
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }      
    }
  }