@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'navigation-search';

.#{$object} {
  &__input-wrapper {
    background: #F1F1F1;
    height: rem-calc(50px);
  }

  &__input-wrapper-inner {
    padding: rem-calc(0) rem-calc($gutter-mobile);
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;
  }

  &__search-icon {
    color: $color-dark;
    font-size: rem-calc(18px);
  }

  &__input {
    position: relative;
    top: 0;
    left: 0;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
    padding-left: calc(rem-calc($gutter-mobile) * 3);
    font-family: $font-primary;
    font-size: rem-calc(16px);
    line-height: rem-calc(18px);
    font-weight: $font-weight-semi-bold;
  }

  &__close {
    margin-left: auto;
    text-decoration: none;
    position: relative;
    z-index: 15;
    color: $color-dark;
    font-size: rem-calc(18px);
    border:none;
    background-color: transparent;
  }

  &__header {
    padding: rem-calc(0) rem-calc($gutter-mobile);
    font-family: $font-secondary;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
    font-weight: $font-weight-bold;
    color: $color-primary;
    margin-bottom: rem-calc(18px);
    margin-top: rem-calc(30px);
    display: block;
    text-transform: uppercase;
  }

  &__suggestions-list {
    margin: rem-calc(0);
    padding: rem-calc(0);
  }

  &__suggestions-list-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: rem-calc(11px);

    &:first-child {
      margin-top: rem-calc(0);
    }
  }

  &__suggestions-list-item-link {    
    align-items: center;
    text-decoration: none;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    color: $color-medium;
    padding: rem-calc(0) rem-calc($gutter-mobile);
    border: none;
    background-color: transparent;
    font-weight: $font-weight-normal;
    color: $color-medium;

    &:hover {
      text-decoration: underline;
    }

    strong {
      color: $color-dark;
      font-weight: $font-weight-bold;
    }
  }

  &__product-tiles {
    display: flex;
    justify-content: flex-start;
    gap: rem-calc(15px);
    flex-basis: 50%;
    flex-shrink: 1;
    flex-grow: 1;
    padding: rem-calc(0) rem-calc($gutter-mobile);
    flex-wrap: wrap;
  }

  &__product-title {
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
  }

  &__articles {
    display: flex;
    flex-direction: column;
    padding: rem-calc(0) rem-calc($gutter-mobile);
  }

  &__article {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: rem-calc(26px);
  }

  &__article-image {
    height: rem-calc(100px);
    margin-right: rem-calc(15px);
    object-fit: cover;
    object-position: center center;
    max-width: 100px;
    border-radius: 6px;
  }

  &__article-info {
    padding-top: rem-calc(10px);
  }

  &__article-title {
    width: 100%;
    display: block;
    text-decoration: none;
    color: $color-dark;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    margin-bottom: rem-calc(2px);

    &:hover {
      text-decoration: underline;
    }
  }

  &__article-description {
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    display: block;
  }

  &__search-btn {
    display: none;
  }

  @media (min-width: map-get($breakpoints, large)) {
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
      margin: rem-calc(0) auto;
      width: 100%;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }

    &__search-icon {
      font-size: rem-calc(35px);
    }

    &__close {
      font-size: rem-calc(18px);
    }

    &__all-suggestions-wrapper {
      max-width: rem-calc($width-max);
      padding: rem-calc(0) rem-calc($gutter-desktop);
      margin: rem-calc(0) auto;
      display: flex;
      flex-wrap: nowrap;
    }

    &__input-wrapper {
      flex-basis: 100%;
      height: rem-calc(100px);
    }

    &__input-wrapper-inner {
      max-width: rem-calc($width-max);
      padding: rem-calc(0) rem-calc($gutter-desktop);
      margin: rem-calc(0) auto;
    }

    &__input {
      padding-left: rem-calc(27px);
      font-size: rem-calc(24px);
      line-height: rem-calc(33px);
      font-weight: $font-weight-bold;
    }

    &__header {
      padding: rem-calc(0);
      font-size: rem-calc(21px);
      line-height: rem-calc(24px);
    }

    &__suggestions-wrapper {
      flex-basis: 22%;
    }

    &__suggested-products-wrapper {
      flex-basis: calc(50% - 60px - 65px);
      padding: rem-calc(0) rem-calc(65px) rem-calc(96px) rem-calc(60px);
      border-left: 1px solid $color-light;
      border-right: 1px solid $color-light;
    }

    &__suggested-articles-wrapper {
      flex-basis: calc(28% - 60px);
      max-width: 325px;
      padding-left: rem-calc(60px);
    }

    &__suggestions-list-item-link {
      font-size: rem-calc(16px);
      line-height: rem-calc(22px);
      padding: rem-calc(0);
    }

    &__product-tiles {
      gap: rem-calc(25px);
      padding: rem-calc(0);
    }

    &__articles {
      padding: rem-calc(0);
    }

    &__article-title {
      font-size: rem-calc(16px);
      line-height: rem-calc(19px);
      margin-bottom: rem-calc(7px);
    }

    &__article-description,
    &__article-description * {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
    }

    &__article-description>p {
      margin: 0;
    }

    &__search-btn {
      display: inline-flex;
      margin-right: rem-calc(56px);
      white-space: nowrap;
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {}
}