@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "search-input";

.#{$object} {
  align-items: center;
  background-color: $color-light-background;
  display: flex;
  margin-bottom: rem-calc(10px);
  padding: rem-calc(0px) rem-calc($gutter-mobile);

  &__wrapper {
    align-items: center;
    display: flex;
    height: rem-calc(50px);
    margin: rem-calc(0px) auto;
    max-width: rem-calc($width-max);
    width: 100%;
  }

  &__icons {
    color: $color-dark;
    font-size: rem-calc(20px);

    &.search:hover {
      cursor: pointer;
    }

    &.close {
      font-size: 18px;
      transform: rotate(45deg);
    }
  }

  &__input {
    background: transparent;
    border: none;
    font-weight: $font-weight-semi-bold;
    line-height: rem-calc(18px);
    margin: rem-calc(0px) rem-calc(20px);
    padding: rem-calc(0px);
    width: 100%;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    margin-bottom: rem-calc(20px);

    &__wrapper {
      height: rem-calc(100px);
    }

    &__input {
      font-size: rem-calc(24px);
      font-weight: $font-weight-bold;
      line-height: rem-calc(33px);
    }

    &__icons {
      font-size: rem-calc(32px);
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    padding: rem-calc(0px) rem-calc($gutter-desktop);
  }
}