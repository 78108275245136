@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'navigation';

.#{$object} {
  position: relative;
  z-index: 16;

  @media (min-width: map-get($breakpoints, medium)) {}

  @media (min-width: map-get($breakpoints, large)) {}

  // When printing from Saved Products page
  @media print {
    display: none;
  }
}