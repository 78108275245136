@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "modal";

#MyModal:focus,
#MyModal:active {
  outline: inherit solid 5px ;
}

.#{$object} {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  justify-content: center;
  align-items: center;


  &__main {
    position: fixed;
    width: 80%;
    height: auto;
    background-color: $color-white;
    border-radius: 6px 6px;
    overflow: hidden;
    &:focus {
      outline: inherit solid 5px ;
    }
  }
  &__header {
    text-align: right;
    padding: rem-calc(10px);
  }
  &__button {
    border: none;
    background-color: transparent;
    text-align: right;
    cursor: pointer;
        &:focus {
          outline: inherit solid 5px ;
          border: 1px solid red;
        }
  }
  &--open {
    display: flex;
  }

  &--closed {
    display: none;
  }
}
