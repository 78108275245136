@import "../../sass/variables";
@import "../../sass/mixins";

$object: "product-spec";

.#{$object} {
  padding: rem-calc(30px) rem-calc($gutter-mobile);

  &__list-wrapper {
    border: 1px solid $color-light;
    border-radius: 6px;
    padding: rem-calc(16px);
  }

  &__mobile-selected-spec-btn {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-dark;
    display: flex;
    justify-content: space-between;
  }

  &__list {
    padding: rem-calc(0);
    margin: rem-calc(23px) rem-calc(0) rem-calc(6px);
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__list-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem-calc(16px);
    &:first-child {
      margin-top: rem-calc(0);
    }
  }

  &__list-item-link {
    border: none;
    outline: none;
    background-color: transparent;
    display: block;
    font-weight: $font-weight-semi-bold;
    @include small-font;
    text-align: left;
    color: $color-dark;
    cursor: pointer;

    &:hover, &:focus{
      border-color: $color-light;
      background: $color-light;
      color: $color-dark;
    }
  }

  &__info-wrapper {
    padding: rem-calc(20px) rem-calc(0);
    font-size: rem-calc(16px);
  }

  &__table {
    border: 0;
    padding: rem-calc(0);
    border-spacing: 0;
    margin-top: rem-calc(10px);
    width: 100%;
    font-size: rem-calc(16px);
    border-collapse: collapse;
    color: $color-dark;
  }

  &__table--header {
    background-color: $color-light;
  }

  &__table td,
  &__table th {
    padding: rem-calc(20px) rem-calc(30px);
    border: 1px solid $color-light;
    text-align: left;
    width: 50%;
  }

  &__table-label {
    background-color: $color-light-background;
  }

  &__table-caption {
    position: absolute;
    left: -9999px;
  }

  &__table-cell--line{
    padding-bottom:10px;
    max-width: 80px;
    
    &:last-child{
      padding-bottom:0;
    }
  }

  &__disclaimer,
  &__disclaimer > * {
    @include extra-small-font;
    color: $color-medium;
  }

  @media (min-width: 360px) {
    &__table-cell--line{
        max-width: none;
    }
  }

  @media (min-width: map-get($breakpoints, "medium")) {
    padding: rem-calc(30px) rem-calc($gutter-desktop);

    &__wrapper {
      border: 1px solid $color-light;
      border-radius: 6px;
      display: flex;
      overflow: hidden;
    }

    &__list-wrapper {
      width: 30%;
      border: 0;
      border-radius: 0;
      border-right: 1px solid $color-light;
      padding: rem-calc(0);
    }

    &__list {
      margin: rem-calc(0);
    }

    &__list-item {
      box-sizing: border-box;
      border-bottom: 1px solid $color-light;
      margin-top: rem-calc(0);

      &:focus-within {
        border: 2px solid $color-dark;
      }
      &.active {
        background-color: $color-primary;
        color: $color-white;
        border-bottom: 1px solid $color-primary;
        &:focus-within {
          border:none;
          border-bottom: 1px solid $color-primary;
        }
        .#{$object}__list-item-link {
          color: $color-white;
          &:hover{
            background-color: $color-primary;
          }

          &:focus {
            background-color: $color-primary;
            border: 2px solid $color-dark;
            min-height: rem-calc(60px);
            padding: rem-calc(8px) rem-calc(28px);
          }
        }
      }
    }

    &__list-item-link {
      min-height: rem-calc(60px);
      padding: rem-calc(10px) rem-calc(30px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: $font-weight-bold;
      @include medium-font;
      width: 100%;
      height: 100%;
      &:focus {
        min-height: rem-calc(57px);
        padding: rem-calc(10px) rem-calc(28px);
        padding-top: rem-calc(9px);
      }
    }

    &__info-wrapper {
      padding: rem-calc(40px) rem-calc(60px);
      width: 70%;
    }
  }
}
