@import "../../sass/variables";
@import "../../sass/mixins";

$object: "search-banner";

.#{$object} {
  &__wrapper {
    padding: rem-calc($gutter-mobile);
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin: rem-calc(0px) auto;
    max-width: rem-calc($width-max);
  }

  &__header {
    font-size: rem-calc(24px);
    line-height: rem-calc(23px);
  }

  &__right {
    justify-self: start;
    align-self: center;
    width: 100%;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__wrapper {
      padding: rem-calc($gutter-desktop);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      column-gap: 10%;
    }

    &__header {
      font-size: rem-calc(32px);
      line-height: rem-calc(31px);
    }

    &__right {
      justify-self: end;
    }
  }
}


.#{$object}.theme-tertiary{
    .#{$object} a{
        color: $color-white;
        // text-decoration: none;
        font-weight: normal;
        &:hover{
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }
    }
  }