@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "fifty-fifty";

.#{$object} {
  margin: rem-calc(0) auto;

  &--primary {
    background-color: $color-white;
  }

  &--secondary {
    background-color: $color-light;
  }

  &--tertiary {
    background-color: $color-primary;
  }

  &--twoImages {
    display: flex;
    flex-direction: column-reverse;

    .#{$object}__content:nth-child(2) .#{$object}__secondImage {
      margin: rem-calc(6px) 0 rem-calc(16px);
    }
  }

  &__wrapper {
    padding: rem-calc(40px) rem-calc($gutter-mobile);
  }

  &__image {
    max-width: 100%;
    border-radius: 6px;
  }

  &__heading {
    font-size: rem-calc(21px);
    line-height: rem-calc(20px);    
    margin: 0 0 rem-calc(10px) 0;
  }

  &__heading--primary {
    color: $color-primary;
  }
  &__heading--secondary {
    color: $color-primary;
  }
  &__heading--tertiary {
    color: $color-white;
  }

  &__description--primary{
    color: $color-medium;
    & * {
      color: $color-medium;
    }
  }

  &__description--secondary {
    color: $color-dark;
    & * {
      color: $color-dark;
    }
  }

  &__description--tertiary {
    color: $color-white;
    a,
    & * {
      color: $color-white;
    }
  }
  
  &__description-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__description-toggle {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-medium;
    color: $color-dark;
    align-self: flex-end;
    margin-bottom: rem-calc(24px);
    margin-right: rem-calc(15px);
  }

  &__description-toggle--primary {
    color: $color-medium;
  }

  &__description-toggle--secondary {
    color: $color-dark;
  }

  &__description-toggle--tertiary {
    color: $color-white;
  }

  &__description-toggle-text {
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: none;
    }
  }

  &__description-toggle-icon {
    display: flex;
    align-items: center;
    margin-left: rem-calc(10px);
  }

  &__cta {
    text-decoration: none;
  }

  &__content {
    position: relative;
  }

  &__video-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  @media (min-width: map-get($breakpoints, medium)) {
    &--twoImages {
      display: flex;
      flex-direction: row;
  
      .#{$object}__content:nth-child(1) {
        align-self: flex-end;
      }

      .#{$object}__content:nth-child(2) .#{$object}__secondImage {
        margin-bottom: 0;
      }
    }

    &__wrapper {
      padding: rem-calc(40px) rem-calc($gutter-desktop);
      max-width: rem-calc($width-max);
      margin: rem-calc(0) auto;
      display: flex;
      gap: 10%;
      align-items: center;
    }

    &__content {
      flex: 1 1 0px;
    }

    &--image-right {
      flex-direction: row-reverse;
    }

    &__heading {
      font-size: rem-calc(32px);
      line-height: rem-calc(31px);
      margin-block-start: rem-calc(0);
      padding-right: rem-calc(30px);
    }

    &__description {
      padding-right: rem-calc(30px);
    }

    &__description-toggle {
      margin-right: rem-calc(45px);
    }
  }
}
