@use "../../sass/variables" as *;
@use "../../sass/mixins" as *;

$object: "material-guide-card";

$mobile-image-width: rem-calc(140px);
$mobile-image-height: $mobile-image-width;
$desktop-image-width: rem-calc(260px);
$desktop-image-height: $desktop-image-width;

.#{$object} {
    box-sizing: border-box;
    display: flex;
    padding: rem-calc(10px);
    border: rem-calc(1px) solid $color-light;
    border-radius: rem-calc(6px);
    min-height: rem-calc(210px);
    gap: rem-calc(10px);

    * {
        box-sizing: border-box;
    }

    &__media {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 42%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: rem-calc(10px);

        &__image {
            width: 100%;
            height: auto;
            max-width: $mobile-image-width;
            max-height: $mobile-image-height;
        }

        &__button {
            height: auto;
            width: 100%;
            padding: rem-calc(10px);
        }
    }

    &__body {
        flex-grow: 2;
        flex-shrink: 1;
        flex-basis: 58%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &__material-attributes,
        &__material-type-icons {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        &__title {
            margin: 0 0 rem-calc(10px) 0;
            color: $color-dark;
            text-transform: none;
            font-family: $font-primary;
            @include small-font;
        }

        &__material-attributes {
            &__item {
                font-size: rem-calc(10px);
                line-height: rem-calc(16px);
                margin-bottom: rem-calc(5px);
                text-decoration: none;
                color: $color-medium;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &--checked {
                    color: $color-dark;
                }

                &__checkmark {
                    color: $color-primary;
                    font-size: rem-calc(8px);
                    margin-right: rem-calc(5px);
                }
            }
        }

        &__material-type-icons {
            text-align: right;

            &__wrapper {
                margin-top: rem-calc(7px);
            }

            &__item {
                display: inline-block;
                font-size: rem-calc(35px);
                max-width: rem-calc(35px);
                max-height: rem-calc(35px);
                vertical-align: bottom;
                color: $color-primary;
                margin-right: rem-calc(10px);

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        padding: rem-calc(20px);
        gap: rem-calc(20px);

        &__media {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 25%;
            max-width: $desktop-image-width;
            gap: 0;

            &__image {
                max-width: $desktop-image-width;
                max-height: $desktop-image-height;
            }
        }

        &__body {
            &__title {
                font-size: rem-calc(21px);
                line-height: rem-calc(24px);
                margin: 0 0 rem-calc(20px) 0;
            }

            &__material-attributes {
                &__item {
                    font-size: rem-calc(14px);
                    margin-bottom: rem-calc(15px);

                    &__checkmark {
                        font-size: rem-calc(10px);
                        margin-right: rem-calc(10px);
                    }
                }
            }

            &__material-type-icons {
                &__wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-top: rem-calc(20px);

                    &__button {
                        margin-left: auto;
                    }
                }

                &__item {
                    font-size: rem-calc(40px);
                    max-width: rem-calc(40px);
                    max-height: rem-calc(40px);
                }
            }
        }
    }
}
