@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'shoppable-banner';

.#{$object} {
  background-color: $color-white;
  position: relative;

  padding: rem-calc($gutter-mobile);

  &.with-message {
    padding-top: 0;
  }

  &__message {
    max-width: rem-calc($width-max);
    margin: rem-calc(15px) auto;
    &__link {
      font-weight: bold;
    }
  }

  &__inner {
    width: calc(100% - $gutter-mobile * 2);
    margin: rem-calc(0) auto;
    align-items: center;
    @media (min-width: $width-max) {
      width: rem-calc($width-max);
      display: inline-block;
      margin-left: calc(50% - rem-calc($width-max) / 2);
    }
  }

  &__banner {
    grid-row: 1;
    grid-column: 1;
    position: relative;
    z-index: 2;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    height: auto;
    display:inline-block;
    vertical-align: top;
    border-radius: rem-calc(15px);
    padding: rem-calc(80px) rem-calc(40px) rem-calc(80px);

    width: 100%;
    @media (max-width: map-get($breakpoints, medium)) {
      padding-left: rem-calc(15px);
      padding-right: rem-calc(15px);
    }
    @media (min-width: map-get($breakpoints, large)) {
      width: 33%;
    }

    &--secondary {
      background-color: $color-light;
    }

    &--tertiary {
      background-color: $color-primary;
      color: $color-white;
    }

    &__header, &__header * {
      font-size: rem-calc(25px);
      line-height: rem-calc(25px);
      font-family: $font-secondary;
      font-weight: $font-weight-bold;
      color: $color-primary;
      text-transform: uppercase;
      display:block;
    }
    @media (min-width: map-get($breakpoints, medium-small)) {
      &__header, &__header * {
        font-size: rem-calc(35px);
        line-height: rem-calc(35px);
      }
    }
    @media (min-width: map-get($breakpoints, medium)) {
      &__header, &__header * {
        font-size: rem-calc(50px);
        line-height: rem-calc(50px);
      }
    }
    @media (min-width: map-get($breakpoints, large)) {
      &__header, &__header * {
        font-size: rem-calc(25px);
        line-height: rem-calc(25px);
      }
    }
    @media(min-width: map-get($breakpoints, xlarge)) {
      &__header, &__header * {
        font-size: rem-calc(35px);
        line-height: rem-calc(35px);
      }
    }
    @media(min-width: map-get($breakpoints, xxlarge)) {
      &__header, &__header * {
        font-size: rem-calc(50px);
        line-height: rem-calc(50px);
      }
    }
    &__header--tertiary, &__header--tertiary * {
      color: $color-white
    }

    &__header {
      margin: rem-calc(0) rem-calc(0) rem-calc(7px) rem-calc(0);
      overflow: hidden;
    }

    &__description--tertiary, &__description--tertiary * {
      color: white
    }

    &__description {
      display:block;
    }

    &__cta {
      max-width: -moz-fit-content;
      max-width: fit-content;
      margin-top: rem-calc(25px);
    }
  }

  &__popup {
    position:absolute;
    width: 400px;
    left: 50%;
    top: 10%;
    background-color: white;
    border-radius: 5px;
    box-shadow: 3px -3px 8px;
    cursor:pointer;

    &__product {
      &__loading {
        text-align: center;
        margin-top: calc(25% - 10px);
      }

      &__banners {
        position: absolute;
        top: rem-calc(15px);
        left: rem-calc(0px);
        z-index: 3;
      }
      &__banner {
        color: $color-white;
        border-radius: 0px 2px 2px 0px;
        font-size: rem-calc(11px);
        font-weight: $font-weight-extra-bold;
        line-height: rem-calc(15px);
        margin: rem-calc(5px) rem-calc(0px) rem-calc(0px);
        max-width: max-content;
        padding: rem-calc(3px) rem-calc(10px) rem-calc(3px) rem-calc(15px);

        &:first-child {
          margin: 0px;
        }

        &.secondary {
          background-color: $color-medium;
        }

        &.primary {
          background-color: $color-primary;
        }
      }
      &__image-wrapper {
        display: inline-block;
        width: 50%;
      }

      &__info-wrapper {
        display: inline-block;
        width: calc(50% - 70px); /*cta button width + button margin*/
      }
      &__product-code {
        margin-top: 5px;
        margin-bottom: 5px;
        font-size: 20px;
      }
      &__text {
        margin: 0px;
        width: 100%;
      }
    }

    &__info {
      &__video-wrapper {
        display:inline-block;
        width: 50%;
      }
      &__info-wrapper {
        padding:10px;
        &.half {
          display:inline-block;
          width:calc(50% - 90px); /*wrapper padding + button width + button margin*/
        }
      }

      &__title {
        margin: 0px;
        font-size: 20px;
      }
      &__subtitle {
        font-size: 20px;
        font-weight: bold;
      }
    }

    &__action-arrow {
        height: 50px;
        width: 50px;
        // position: absolute;
        right: 10px;
        bottom: calc(50% - 25px);
        text-align: center;
        font-size: 50px;
        font-weight: 100;
        color: #333;
        background-color: #fd5;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        text-decoration: none;
        justify-content: center;
        align-content: center;
        & > .arrow {
          position: relative;
          align-self: center;
        }
    }
  }

  &__media {
    grid-row-start: 1;
    grid-column-start: 1;
    display:inline-grid;
    position: relative;

    width: 100%;
    padding: 0;
    padding-top: rem-calc($gutter-mobile);
    @media (min-width: map-get($breakpoints, large)) {
      width: calc(67% - rem-calc($gutter-mobile));
      padding-left: rem-calc($gutter-mobile);
      padding-top: 0;
      &.media-left {
        float: left;
        padding-left: 0;
        padding-right: rem-calc($gutter-mobile);
      }
    }

    &__cta {
      position: absolute;
      max-width: -moz-fit-content;
      max-width: fit-content;
      top: rem-calc(80px);
      left: rem-calc(40px);
    }

    vertical-align: top;
    &__image {
      width: 100%;
      border-radius: rem-calc(15px);
      object-fit: cover;
    }

    &__video {
      border-radius: rem-calc(15px);
      overflow: hidden;
    }
    &__video, &__image{
      grid-row: 1;
      grid-column:1;
    }
  }
}
