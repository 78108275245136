@use "../../sass/variables"as *;
@use "../../sass/mixins"as *;

$object: "compatible-products";

.#{$object} {
    padding: rem-calc($gutter-mobile) rem-calc($gutter-mobile) rem-calc(30px);
    max-width: rem-calc($width-max);
    margin: rem-calc(0) auto;

    .swiper-wrapper {
        max-height: 400px;
    }

    .swiper-slide {
        height: auto;
    }

    &__inner {
        max-width: rem-calc($width-max);
        margin: rem-calc(0) auto;
    }

    &__title {
        margin: rem-calc(0);

        &-wrapper {
            margin-bottom: rem-calc(20px);
        }
    }

    &__carousel {
        box-sizing: border-box;
        --swiper-navigation-size: 20px;
        height: 100%;
        max-height: 380px;

        &__card {
            position: relative;
            box-sizing: border-box;
            display: inline-flex;
            flex-direction: column;
            background-color: $color-white;
            border-radius: 6px;
            border: 1px solid $color-light-background;
            width: 100%;
            height: 100%;
            min-height: rem-calc(190px);
            padding: rem-calc(10px);

            .add-product {
                position: absolute;
                top: rem-calc(10px);
                right: rem-calc(10px);
            }

            &__image {
                max-width: 90%;
                height: 90%;

                &-wrapper {
                    flex: auto 2 0;
                    flex-direction: column;
                    max-height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &__name {
                @include extra-small-font;
                margin: rem-calc(10px) 0 0;
                color: $color-dark;
                text-align: center;
            }

            &__link {
                display: flex;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__cta-wrapper {
        text-align: center;
        margin-top: rem-calc(20px);
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        padding: rem-calc(30px) rem-calc($gutter-desktop);

        &__carousel__card {
            padding: rem-calc(20px);

            .add-product {
                top: rem-calc(20px);
                right: rem-calc(20px);
            }

            &__name {
                font-size: rem-calc(18px);
                line-height: rem-calc(23px);
            }
        }

    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        padding: rem-calc(30px) rem-calc($gutter-desktop);

        &__inner {
            display: grid;
            grid-template-columns: 25% 75%;
        }

        &__title {
            font-size: rem-calc(42px);
            line-height: rem-calc(40px);

            &-wrapper {
                margin-bottom: rem-calc(40px);
            }
        }

        &__product-grid {

            &__products {
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: rem-calc(40px);


                &__view-more {
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    border: 2px solid $color-dark;
                    text-decoration: none;

                    .icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: rem-calc(23px);
                        font-size: rem-calc(20px);
                    }
                }

                &__view-more.#{$object}__carousel__card {
                    padding: rem-calc(0) rem-calc(40px);
                }
            }

        }
    }
}