@use "../../sass/_variables"as *;
@use "../../sass/_mixins"as *;

$object: "related-documents";
$image-width: 177px;

.#{$object} {
    padding: rem-calc(20px) rem-calc($gutter-mobile);
    max-width: rem-calc($width-max);
    margin: rem-calc(0) auto;

    &__title {
        margin: rem-calc(0) rem-calc(0) rem-calc(20px);
    }

    &__list {
        margin: rem-calc(0);
        padding: rem-calc(0);
        list-style: none;

        &__item {
            border: 1px solid $color-light;
            border-radius: 6px;
            box-sizing: border-box;
            display: flex;
            margin-bottom: rem-calc(20px);
            padding: rem-calc(10px) rem-calc(20px) rem-calc(20px) rem-calc(10px);

            &:last-of-type {
                margin-bottom: rem-calc(0);
            }

            &__left {
                margin-right: rem-calc(20px);
                max-width: rem-calc(100px);
            }

            &__right {
                margin-top: rem-calc(10px);
            }

            &__image-wrapper {
                width: rem-calc(100px);
                height: rem-calc(100px);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }

            &__category {
                margin: rem-calc(0) rem-calc(0) rem-calc(5px) rem-calc(0);
                text-transform: capitalize;
                color: $color-primary;
                @include extra-small-font;
            }

            &__title {
                margin: rem-calc(0);
            }

            &__cta {
                height: auto;
                margin-top: rem-calc(20px);
                max-width: rem-calc(200px);
                min-height: rem-calc(40px);
                padding: rem-calc(8px);
                text-align: center;
                width: 100%;
            }

            &.clearfix::after {
                visibility: hidden;
                display: block;
                content: "";
                clear: both;
                height: 0;
            }
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        padding: rem-calc(20px) rem-calc($gutter-desktop);

        &__list {
            &__item {
                align-items: center;
                min-height: rem-calc(145px);
                padding: rem-calc(20px);

                &__left,
                &__right {
                    float: none;
                }

                &__left {
                    width: auto;
                    max-width: none;
                }

                &__image-wrapper {
                    width: rem-calc($image-width);
                    height: rem-calc(100px);
                    max-width: rem-calc($image-width);
                    max-height: rem-calc(100px);

                    img {
                        display: block;
                        height: 100%;
                        object-fit: contain;
                        object-position: center center;
                        width: 100%;
                    }
                }

                &__right {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: auto;
                    flex: auto 1 1;
                    margin-top: rem-calc(0px);
                }

                &__category {
                    @include small-font;
                }

                &__title {
                    font-size: rem-calc(21px);
                    line-height: rem-calc(24px);
                }

                &__cta {
                    margin: rem-calc(0) rem-calc(0) rem-calc(0) rem-calc(20px);
                    min-height: rem-calc(50px);
                    min-width: rem-calc(200px);
                }
            }
        }
    }
}