@import "../../sass/variables";
@import "../../sass/mixins";

$object: "footer";
.#{$object} {
  background-color: $color-primary;

  & > * {
    color: $color-white !important;
  }

  &__wrapper {
    padding: rem-calc($gutter-mobile);
  }

  &__logo-wrapper {
    text-align: center;
  }

  &__logo {
    max-width: rem-calc(198px);
    width: 100%;
  }

  &__header {
    padding: rem-calc(0);
    width: 100%;
    background-color: transparent;
    border: 0;
    font-size: rem-calc(18px);
    line-height: rem-calc(18px);
    color: inherit;
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-top: rem-calc(25px);
    padding-bottom: rem-calc(25px);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__social-wrapper {
    margin: rem-calc(50px) rem-calc(0);
    text-align: center;
  }

  &__social-icon {
    font-size: rem-calc(18px);
    padding: rem-calc(15px);
    text-decoration: none;
    color: inherit;
  }

  &__copyright {
    font-family: $font-primary;
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    text-align: center;
  }

  @media (min-width: map-get($breakpoints, medium)) {
  }

  @media (min-width: map-get($breakpoints, large)) {
    &__wrapper {
      padding: rem-calc($gutter-desktop);
    }

    &__logo-wrapper {
      text-align: left;
    }  

    &__columns {
      display: flex;
      justify-content: space-between;
      gap: rem-calc(20px);
      margin-bottom: rem-calc(40px);
    }

    &__column {
      flex: 1 1 0px;
    }

    &__header {
      text-align: left;
      padding-top: rem-calc(0px);
      padding-bottom: rem-calc(10px);
    }

    &__header-icon {
      display: none;
    }

    &__social-wrapper {
      margin: rem-calc(20px) rem-calc(0);
      max-width: rem-calc(200px);
    }

    &__copyright {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
    }
  }

  // When printing from Saved Products page
  @media print {
    display: none;
  }
}
