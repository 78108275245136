@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'plant-locations';

.#{$object} {
  max-width: rem-calc($width-max);
  margin: rem-calc(0) auto;
  padding: rem-calc(15px) rem-calc($gutter-mobile) rem-calc(30px);
  margin-bottom: rem-calc(34px);

  &__desktop-location-selector-wrapper {
    display: none;
  }

  &__location-selector-wrapper {
    border: 1px solid $color-light;
    border-radius: 6px;
    padding: rem-calc(16px);
    margin-bottom: rem-calc(30px);
    width: 100%;
    box-sizing: border-box;
  }

  &__location-selector-btn {
    background-color: transparent;
    outline: none;
    border: none;
    width: 100%;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-dark;
    display: flex;
    justify-content: space-between;
  }

  &__location-selector {
    padding: rem-calc(0);
    margin: rem-calc(23px) rem-calc(0) rem-calc(6px);
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__location-selector-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem-calc(16px);

    &:first-child {
      margin-top: rem-calc(0);
    }
  }

  &__location-selector-item-link {
    border: none;
    background-color: transparent;
    display: block;
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    text-align: left;
  }

  &__current-results {
    padding: rem-calc(0);
    margin: rem-calc(0);
  }

  &__current-results-item {
    border-bottom: 1px solid $color-light;
    padding: rem-calc(18px) rem-calc(10px) rem-calc(20px);
    display: flex;
    cursor: pointer;

    &:first-child {
      border-top: 1px solid $color-light;
    }

    &.active,
    &:hover {
      background-color: $color-light-background;
      outline: solid black 2px;
    }
  }

  &__current-results-item-number-wrapper {
    position: relative;
    border: none;
    background-color: transparent;
    display: flex;
  }

  &__current-results-item-number-icon {
    color: $color-primary;
    font-size: rem-calc(26px);
  }

  &__current-results-item-number {
    position: absolute;
    top: rem-calc(10px);
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(14px);
    color: $color-white;
  }

  &__current-results-item-content-wrapper {
    margin-left: rem-calc(20px);
  }

  &__current-results-item-content-name {
    display: block;
    font-weight: $font-weight-bold;
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    color: $color-dark;
    margin-bottom: rem-calc(2px);
  }

  &__current-results-item-content-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: $font-weight-medium;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-medium;
    margin-bottom: rem-calc(10px);
  }

  &__current-results-item-content-type-item {
    display: block;
    width: 100%;
  }

  &__current-results-item-pipe {
    font-weight: $font-weight-bold;
    display: flex;
    align-items: center;
    font-size: rem-calc(18px);
  }

  &__current-results-item-content-label {
    display: inline-block;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-dark;
    margin-top: rem-calc(10px);
    margin-bottom: rem-calc(5px);
  }

  &__current-results-item-content-value {
    display: block;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-medium;
  }

  &__map-wrapper {
    display: none;
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    padding: rem-calc(44px) rem-calc($gutter-desktop) rem-calc(80px);

    &__location-selector-wrapper {
      display: none;
    }

    &__wrapper {
      border: 1px solid $color-light;
      border-radius: 6px;
      display: flex;
      height: rem-calc(550px);
      overflow: hidden;

    }

    &__desktop-location-selector-wrapper {
      display: flex;
      flex-direction: column;
      width: 17.8%;
      border-right: 1px solid $color-light;
    }

    &__desktop-location-selector {
      list-style-type: none;
      margin: rem-calc(0);
      padding: rem-calc(0);
      width: 100%;
    }

    &__desktop-location-selector-item {
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid $color-light;

      &.active {
        background-color: $color-primary;
        color: $color-white;
        border-bottom: 1px solid $color-primary;

        .#{$object}__desktop-location-selector-item-link-icon {
          display: flex;
        }

        .#{$object}__desktop-location-selector-item-link {
          color: $color-white;
        }
      }
    }

    &__desktop-location-selector-item-link-icon {
      display: none;
      font-size: rem-calc(10px);
      line-height: rem-calc(19px);
      padding-left: rem-calc(10px);
      height: rem-calc(14px);
    }

    &__desktop-location-selector-item-link {
      cursor: pointer;
      min-height: rem-calc(60px);
      padding: rem-calc(12px) rem-calc(20px);
      border: none;
      outline: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      width: 100%;
      height: 100%;
      color: $color-dark;
      text-align: left;

      &:hover, &:focus, &:focus-within {
        border: solid black 2px;
      }
    }

    &__current-results-wrapper {
      overflow: auto;
      padding: rem-calc(10px);
      width: 34.9%
    }

    &__current-results-item-content-wrapper {
      margin-left: rem-calc(10px);
      width: 100%;
    }

    &__current-results-item-content-name {
      font-size: rem-calc(18px);
      line-height: rem-calc(25px);
    }

    &__current-results-item-sections {
      display: flex;
      gap: rem-calc(0 10px);
    }

    &__current-results-item-section {
      width: 50%;
    }

    &__map-wrapper {
      width: 47.3%;
      height: 100%;
      display: block;
      z-index: 5;
    }

    .leaflet-container {
      height: rem-calc(550px);
    }
  }
}