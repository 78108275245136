@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'pdp-header';

.#{$object} {
  padding: rem-calc(30px) rem-calc($gutter-mobile) rem-calc(0);

  &__swiper-button-prev, &__swiper-button-next {
    background-color: transparent;
    background-image: linear-gradient(to left, $color-white, transparent);
    color:black;
    width: rem-calc(40px);
    height: rem-calc(60px);
    display: inline-block;
    vertical-align: top;
    border: 0px;
    font-size: 40px;
    cursor:pointer;
    position: absolute;
    right: 0px;
    z-index: 2;

    &:hover, &:focus {
      border: 2px solid $color-dark;
      border-radius: 5px;
      background-color: $color-white;
      background-image: none;
    }

    /* position the chevron just so */
    &__content {
      position: relative;
      top: -3px;
    }

    @media(min-width: map-get($breakpoints, medium-small)) {
      width: rem-calc(60px);
      height: rem-calc(60px);
    }

    @media(min-width: map-get($breakpoints, large)) {
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
      width: rem-calc(75px);
      height: rem-calc(75px);
      vertical-align: middle;
      right: auto;
      bottom: rem-calc(20px);
      right: auto;
      bottom: 0px;
      left: 0px;
    }
  }

  &__carousel {
    position:relative;
  }

  & .swiper {
    width: 100%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: rem-calc(20px);

    @media(min-width: map-get($breakpoints, large)) {
      width: auto;
      height: 100% !important;
      margin-right: rem-calc(40px);
      margin-bottom: rem-calc(0px);
    }
  }

  @media(min-width: map-get($breakpoints, large)) {
    &__carousel {
        height: rem-calc(595px);
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
  }

  &__heading-wrapper {
    max-width: rem-calc($width-max);
    margin: rem-calc(0px) auto;
  }

  &__imageControls {
    position: absolute;
    bottom: 0px;
    z-index: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    visibility: hidden;
    opacity: 0;
    transition: visibility 300ms ease-in-out, opacity 300ms ease-in-out;

    &.left {
      left: 0;
    }
    
    &.right {
      right: 0;
    }

    & button {
      background-color: $color-white;
      border: 1px solid $color-primary-dark;
      width: 30px;
      height: 30px;
      line-height: 25px;
      font-size: 22px;
      text-align: center;
      color: $color-primary-dark;
    }

    & button:hover {
      background-color: $color-primary;
      border: 1px solid $color-primary;
      color: $color-light;
    }

    &__zoomControls {
      display: flex;
      flex-flow: column wrap;
    }

    &__panControls {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
    }

    &__zoomControls button:first-child {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 0;
      font-weight: 900;
    }
    &__zoomControls button:last-child {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-weight: 900;
    }

    &__panControls {
      .pan-arrow-up {
        transform: rotate(-90deg);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 0;
        font-weight: 200;
      
        &:before {
          content: "\2794";
        }
      }

      .pan-arrow-down {
        transform: rotate(90deg);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 0;
        font-weight: 200;
    
        &:before {
          content: "\2794";
        }
      }

      .pan-arrow-left {
        transform: rotate(180deg);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-left: 0;
        font-weight: 200;
    
        &:before {
          content: "\2794";
        }
      }

      .pan-arrow-right { 
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        font-weight: 200;
    
        &:before {
          content: "\2794";
        }
      }
    }
  }

  &__heading-product-family-name {
    font-weight: $font-weight-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    color: $color-primary;
    display: block;
    margin-bottom: rem-calc(7px);
  }

  &__heading-product-name {
    font-family: $font-primary;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(24px);
    line-height: rem-calc(30px);
    color: $color-dark;
    display: block;
    margin-bottom: rem-calc(15px);
    text-transform: none;
  }

  &__heading-info-line {
    margin-bottom: rem-calc(30px);
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-medium;
  }

  &__heading-product-code {
    display: inline-block;
    color: $color-dark;
    font-weight: $font-weight-bold;
  }

  &__heading-compatible-products {
    cursor: pointer;
    border:none;
    background-color: transparent;
    display: inline-block;
    text-decoration: underline;
    color: $color-dark;
    font-weight: normal;

    &:hover {
      text-decoration: none;
    }
  }

  &__active-image-zoom-area {
    display: none;
  }

  &__active-image-wrapper {
    position: relative;
    margin-bottom: rem-calc(5px);
    display: flex;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    margin: 2px;
  }

  &__active-image-zoom-wrapper {
    position: relative;
    //Limit the zoom to large or above like previous implementation - the reference goes null when the screen is resized
    @media (min-width: map-get($breakpoints, "large")) {
      //limit the zoom functionality to non-touch devices as touch users would expect to use typical pinch to zoom
      @media (hover: hover), (-moz-touch-enabled: 0), (pointer:fine) {
        &:focus .pdp-header__imageControls, &:focus-within .pdp-header__imageControls, &:hover .pdp-header__imageControls {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }



  &__active-image {
    width: auto;
    max-width: 400%;
    max-height: 400%;
    min-width: 20%;
    min-height: 20%;
    -o-object-fit: contain;
       object-fit: contain;
    transition: transform 300ms ease-in-out;
  }

  &__active-image.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  &__active-image-video-button {
    border: none;
    width: 100%;
    height: 100%;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &__active-image-video-button-icon {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-color: $color-black;
    color: $color-white;
    font-size: rem-calc(60px);
  }

  &__flag-section {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__active-image-pdp-label {
    border: 1px solid $color-primary-dark;
    background-color: $color-primary-dark;
    border-radius: 0px 2px 2px 0px;
    color: $color-white;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    padding: rem-calc(5px) rem-calc(20px);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__active-image-pdp-icon {
    padding: 0 rem-calc(10px);
    width:rem-calc(50px);
  }

  &__customization {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: rem-calc(15px);
  }

  &__customization-text {
    background: $color-medium;
    font-weight: $font-weight-extra-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(19px);
    color: $color-white;
    padding: rem-calc(12px) rem-calc(10px);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__alt-images-wrapper {
    display: flex;
    align-items: center;
    gap: rem-calc(0 10px);
    margin-bottom: rem-calc(20px);
    margin-top: rem-calc(0px);
    padding: rem-calc(0px);
  }

  &__alt-image {
    cursor: pointer;
    width: rem-calc(60px);
    height: rem-calc(60px);
    border-radius: 6px;
    border: 1px solid $color-light;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.active {
      border-color: $color-medium;
    }

    img {
      border-radius: 6px;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__alt-image-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    opacity: 0.6;
    background-color: $color-black;
    color: $color-white;
    font-size: rem-calc(20px);
  }

  &__alt-image-arrow-btn{
    background-color:transparent;
    border:0;
    cursor:pointer;
  }

  &__alt-image--list-item {
    list-style: none;
  }

  &__features-wrapper {
    margin-bottom: rem-calc(20px);
  }

  &__features-list {
    padding: rem-calc(0);
    margin: rem-calc(0);
    width: 100%;
    display: flex;
    flex-direction: column;
    list-style-position: outside;
    margin-left: rem-calc($gutter-mobile);
  }

  &__feature-item {
    width: 100%;
    font-weight: $font-weight-medium;
    font-size: rem-calc(14px);
    line-height: rem-calc(24px);
    color: $color-medium;
  }

  &__product-prints-wrapper {
    margin-bottom: rem-calc(30px);
  }

  &__product-prints-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: rem-calc(0 10px);
  }

  &__product-print-item {
    border-radius: 6px;
    border: 1px solid $color-light;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem-calc(45px);
    height: rem-calc(45px);

    &.active {
      border-color: $color-medium;
    }

    img {
      border-radius: 6px;
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__product-prints-items {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: rem-calc(10px);
  }

  &__product-print-current {
    display: flex;
    align-items: center;
    font-size: rem-calc(12px);
    line-height: rem-calc(18px);
  }

  &__product-print-label {
    font-weight: $font-weight-semi-bold;
  }

  &__add-wrapper {
    margin-bottom: rem-calc(20px);
  }

  &__description {
    margin-bottom: rem-calc(20px);
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: $color-medium;
  }

  &__description-toggle {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-medium;
    color: $color-dark;
  }

  &__description-toggle-text {
    text-decoration: underline;
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;

    &:hover {
      text-decoration: none;
    }
  }

  &__description-toggle-icon {
    display: flex;
    align-items: center;
    margin-left: rem-calc(10px);
  }

  &__product-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: rem-calc(30px);
    position: relative;
  }

  &__product-tag {
    display: flex;
    align-items: center;
    width: calc(50% - 30px);
  }

  &__product-tag-icon {
    font-size: rem-calc(24px);
    color: $color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: rem-calc(10px);
    
    &.darticon-proPlanet_Leaf {
      color: $color-proplanet-leaf;
      &.blue {
        color: $color-proplanet-blue;
      }
    }
  }

  &__product-tag-name,
  &__product-tags-more-message-wrapper {
    font-weight: $font-weight-semi-bold;
    font-size: rem-calc(14px);
    line-height: rem-calc(18px);
    color: $color-dark;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    padding: rem-calc(50px) rem-calc($gutter-desktop) rem-calc(0);
  }

  @media (min-width: map-get($breakpoints, large)) {
    &__active-image-zoom-wrapper {
      position: relative;

      &.active {
        .#{$object}__active-image-zoom-box {
          display: block;
        }

        .#{$object}__active-image-zoom-area {
          display: block;
        }
      }
    }

    &__active-image-zoom-box {
      display: none;
      position: absolute;
      border: 1px solid $color-light;
      /*set the size of the lens:*/
      width: rem-calc(120px);
      height: rem-calc(120px);
    }

    &__active-image-zoom-area {
      position: absolute;
      top: 0;
      left: 100%;
      display: none;
      border: 1px solid $color-light;
      /*set the size of the result div:*/
      width: rem-calc(400px);
      height: rem-calc(400px);
      z-index: 5;
    }

    &__desktop-active-image-wrapper {
      width: 100%;
    }

    &__heading-wrapper {
      display: flex;
      gap: 0 7%;
    }

    &__imaging-wrapper {
      display: flex;
      width: 100%;
      max-width: 50%;
    }

    &__information-wrapper {
      width: 100%;
    }

    &__alt-images-wrapper {
      flex-direction: column;
      gap: 10px 0;
      margin-right: rem-calc(40px);
    }

    &__active-image-wrapper {
      margin-bottom: rem-calc(20px);
    }

    &__active-image {
      // max-width: 100%;
      position: relative;
    }

    &__alt-image {
      width: rem-calc(75px);
      height: rem-calc(75px);
    }

    &__customization-text {
      font-size: rem-calc(16px);
      line-height: rem-calc(22px);
    }

    &__hover-image-message-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: rem-calc(20px);
    }

    &__hover-image-message {
      font-weight: $font-weight-medium;
      font-size: rem-calc(14px);
      line-height: rem-calc(18px);
      display: flex;
      align-items: center;
      padding: rem-calc(12px) rem-calc(10px);
    }

    &__heading-product-name {
      font-size: rem-calc(32px);
      margin-top: rem-calc(15px);
      margin-bottom: rem-calc(20px);
    }

    &__heading-info-line {
      font-size: rem-calc(16px);
      line-height: rem-calc(18px);
    }

    &__features-wrapper {
      margin-bottom: rem-calc(30px);
    }

    &__feature-item {
      font-size: rem-calc(16px);
      line-height: rem-calc(26px);
    }

    &__product-print-item {
      width: rem-calc(50px);
      height: rem-calc(50px);
    }

    &__description {
      margin-bottom: rem-calc(30px);
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }

    &__add-wrapper {
      margin-bottom: rem-calc(30px);
    }

    &__product-tags-wrapper {
      gap: 30px 1.5%;
      justify-content: flex-start;
    }

    &__product-tag {
      width: 30%;
    }

    &__product-tags-more-message-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}