@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "sort-by";

.#{$object} {
    display: flex;
    align-items: center;
    color: $color-dark;

    &__mobile-button {
        width: rem-calc(30px);
        height: rem-calc(30px);
        background: transparent;
        border: none;
        padding: rem-calc(0);
        text-align: center;
        color: $color-dark;
        font-size: rem-calc(20px);
        cursor: pointer;
        margin-right: rem-calc(7px);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__value {
        @include small-font;
    }

    &__select-set {
        display: inline-flex;
        align-items: center;
    }

    &__select {
        position: relative;
        width: auto;
        background: $color-white;
        text-transform: capitalize;
        padding: rem-calc(0) rem-calc(10px);

        &-wrapper {
            position: relative;
        }

        &__label {
            margin-right: rem-calc(5px);
            @include small-font;
        }

        &__option {
            text-transform: capitalize;
            font-weight: $font-weight-medium;
            appearance: none;
            padding: rem-calc(0);
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        &__select {
            &__label {
                margin-right: rem-calc(10px);
                @include medium-font;
            }
        }
    }

    @media (min-width: map-get($breakpoints, "large")) {
        &__select {
            width: rem-calc(255px);
            padding: rem-calc(0) rem-calc(20px);
        }
    }
}
