@use "../../sass/variables"as *;
@use "../../sass/mixins"as *;

$object: "icon-grid";

.#{$object} {
    padding: rem-calc(40px) rem-calc($gutter-mobile) rem-calc(60px);
    background-color: $color-primary-dark;
    text-align: center;

    *:not(.btn) {
        color: $color-white;
    }

    &__inner {
        max-width: rem-calc($width-max);
        margin: rem-calc(0) auto;
    }

    &__title {
        margin: rem-calc(0) rem-calc(0) rem-calc(10px);
    }

    &__description * {
        @include small-font;
    }

    &__grid {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        margin: rem-calc(40px) rem-calc(0) rem-calc(-40px);
        padding: rem-calc(0);

        &__item {
            flex: 0 1 calc(50% - 20px);
            list-style: none;
            margin-bottom: rem-calc(40px);
            padding: rem-calc(0) rem-calc(10px);

            &__link {
                display: flex;
                flex-direction: column;
                flex-flow: column;
                height: 100%;
                font-weight: $font-weight-medium;
            }

            &__image-wrapper {
                flex: 1 0 80px;
                height: 100%;
                max-height: 80px;
            }

            &__image-wrapper+&__text-wrapper {
                margin-top: rem-calc(20px);
            }

            &__image {
                aspect-ratio: 1.2;
                height: 100%;
                max-width: rem-calc(95px);
                max-height: rem-calc(80px);
                object-fit: contain;
            }

            &__label {
                font-size: rem-calc(16px);
                line-height: rem-calc(18px);
                font-family: $font-primary;
                font-weight: $font-weight-semi-bold;
                text-transform: none;
                margin: rem-calc(0);
            }

            &__label+&__description {
                margin-top: 10px;
            }

            &__description {
                p {
                    margin: rem-calc(0);
                    font-size: rem-calc(14px);
                }

                &.has-link {
                    font-weight: $font-weight-medium;
                }
            }
        }
    }

    &__cta {
        margin-top: rem-calc(40px);
    }

    &__cta.btn.btn--tertiary {
        color: #333;
        span, a{
            color: #333;
        }
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        padding: rem-calc(60px) rem-calc($gutter-desktop);

        &__description * {
            @include medium-font;
        }

        &__grid {
            display: grid;
            column-gap: rem-calc(20px);
            row-gap: rem-calc(60px);
            margin: rem-calc(60px) rem-calc(0) rem-calc(0);

            &--three-columns,
            &--five-columns {
                grid-template-columns: repeat(3, 1fr);
            }

            &--centered {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                column-gap: rem-calc(100px);

                .#{$object}__grid__item {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: 130px;
                    display: block;
                }
            }

            &__item {
                margin: rem-calc(0);
                padding: rem-calc(0);

                &__label {
                    font-size: rem-calc(21px);
                    line-height: rem-calc(24px);
                }
            }
        }

        &__cta {
            margin-top: rem-calc(80px);
        }
    }

    @media (min-width: map-get($breakpoints, "large")) {
        &__description {
            width: 54%;
            margin: rem-calc(0) auto;
        }

        &__grid--five-columns {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}