@import "../../sass/variables";

$object: "search-text";

.#{$object} {
  &__input-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
  }

  &__input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid $color-medium;
    border-right: 0;
    flex: 1 1 auto;
    width: 1%; //Adding this width fixes an issue with the textbox when constrained to a smaller width causing search icon to wrap under the textbox
  }

  &__btn-wrapper {
    display: flex;
  }

  &__btn {
    border-radius: 0 6px 6px 0;
    border: 1px solid $color-medium;
    border-left: 0;
    padding: rem-calc(0) rem-calc(14px);
    height: inherit;
  }

  &__btn:hover {
    border-color: $color-medium;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__input-group {
      justify-content: flex-end;
    }

    &__btn {
      padding: rem-calc(0) rem-calc(18px);
    }
  }
}
