@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "search-tile";

.#{$object} {
  background-color: $color-white;
  max-width: 100%;
  text-decoration: none;
  border-radius: 6px;
  padding: rem-calc(0) rem-calc(20px);

  &.condensed {
    block-size: fit-content;
  }

  &--primary {
    border: 1px solid $color-light;
  }

  &__header {
    color: $color-primary !important; // important to override global theme font color;
    padding-top: rem-calc(30px);
    margin: rem-calc(0);
    font-size: rem-calc(24px);
    &.condensed {
      padding-top: rem-calc(20px);
      font-size: rem-calc(20px);
    }
  }

  &__description p {
    color: $color-medium !important; // important to override global theme font color;
    font-weight: $font-weight-normal;
  }

  &__description.condensed p {
    margin-top: rem-calc(3px);
    margin-bottom: rem-calc(3px);
  }

  &__search {
    padding-top: rem-calc(30px);
    padding-bottom: rem-calc(50px);
    &.condensed {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(20px);
      & .search-text__input {
        padding: rem-calc(10px);
        height: rem-calc(14px);
      }
      & .search-text__btn {
        padding-left: rem-calc(12px);
        padding-right: rem-calc(12px);
        font-size: rem-calc(12px);
      }
    }
  }

  &__label {
    margin-bottom: rem-calc(10px);
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary !important; // important to override global theme font color;

    &__header {
      font-size: rem-calc(32px);
      line-height: rem-calc(31px);
      max-width: rem-calc(340px);
      &.condensed {
        font-size: rem-calc(24px);
      }
    }
  }
}
