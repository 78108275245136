@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'flyout';

.#{$object} {

  height: 100%;

  @media (min-width: map-get($breakpoints, large)) {
    height: auto;
    border-top: 1px solid $color-light;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  }
}