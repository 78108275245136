@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'desktop-navigation';

.#{$object} {
  &__content {
    position: fixed;
    background-color: $color-white;
    width: 100%;
  }

  &__spacer {
    width:100%;
    height:150px;
  }

  @media (min-width: map-get($breakpoints, large)) {

    &__utility-nav {
      background-color: $color-primary;
      height: rem-calc(45px);
      display: flex;
      align-items: center;
    }

    &__utility-nav-inner {
      max-width: rem-calc($width-max);
      padding: rem-calc(0) rem-calc($gutter-desktop);
      margin: rem-calc(0) auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
    }

    &__utility-list {
      list-style-type: none;
      display: flex;
      padding: rem-calc(0);
      margin: rem-calc(0);
    }

    &__utility-list-item {
      border-left: 1px solid rgba(255, 255, 255, 0.45);
      margin-left: rem-calc(21px);
      padding-left: rem-calc(21px);

      &:first-child {
        border-left: none;
        margin-left: rem-calc(0);
        padding-left: rem-calc(0);
      }
    }

    &__utility-list-item-link {
      color: $color-white;
      display: flex;
      align-items: center;
      font-size: rem-calc(14px);
      line-height: rem-calc(18px);
      text-decoration: none;

      &:hover {
        text-decoration: underline;

        .#{$object}__utility-list-item-link-icon,
        .#{$object}__utility-list-item-link-icon::before {
          display: inline-block;
          text-decoration: none !important;
        }
      }
    }

    &__utility-list-item-link-icon {
      display: flex;
      align-items: center;
      font-size: rem-calc(18px);
      margin-right: rem-calc(9px);
    }

    &__main-nav-area {
      height: rem-calc(105px);
      max-width: rem-calc($width-max);
      padding: rem-calc(0) rem-calc($gutter-desktop);
      margin: rem-calc(0) auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      width: rem-calc(120px);
      display: block;
    }

    &__main-nav {
      display: flex;
      align-items: center;
    }

    &__main-nav-list {
      display: flex;
      align-items: center;
      padding: rem-calc(0);
      margin: rem-calc(0);
      list-style-type: none;
    }

    &__main-nav-list-item {
      margin-left: rem-calc(30px);

      &:first-child {
        margin-left: rem-calc(0);
      }
    }

    &__main-nav-list-item-link {
      display: flex;
      align-items: center;
      color: $color-dark;
      font-size: rem-calc(16px);
      line-height: rem-calc(15px);
      font-weight: $font-weight-bold !important;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
      background-color: transparent;
      border: none;

      &:hover,
      &:active {
        text-decoration: none;
      }

      &.active {
        color: $color-primary;

        .#{$object}__main-nav-list-item-link-icon-active {
          display: flex;
        }

        .#{$object}__main-nav-list-item-link-icon-dropdown {
          display: none;
        }
      }
    }

    &__main-nav-list-item-link-icon-dropdown,
    &__main-nav-list-item-link-icon-active {
      display: flex;
      align-items: center;
      font-size: rem-calc(10px);
      margin-left: rem-calc(10px);
    }

    &__main-nav-list-item-link-icon-active {
      display: none;
    }

    &__actions-wrapper {
      display: flex;
      justify-content: space-between;

      .btn {
        margin-left: rem-calc(10px);

        &:first-child {
          margin-left: rem-calc(0);
        }
      }
    }

    &__serach-btn,
    &__how-to-buy-btn {
      padding: rem-calc(0) rem-calc(20px);
    }

    &__how-to-buy-btn-icon,
    &__serach-btn-icon-dropdown {
      margin-right: rem-calc(10px);
    }

    &__search-wrapper {
      width: 100%;
      position: absolute;
      top: 0;
      left: auto;
      margin: rem-calc(0) auto;
      height: auto;
      background-color: $color-white;
    }

    &__flyout-wrapper {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: $color-white;
    }
  }

  @media (min-width: map-get($breakpoints, xxlarge)) {
    &__logo {
      width: rem-calc(170px);
    }
    
    &__main-nav-list-item {
      margin-left: rem-calc(65px);

      &:first-child {
        margin-left: rem-calc(0);
      }
    }

    &__serach-btn,
    &__how-to-buy-btn {
      padding: rem-calc(0) rem-calc(40.1px);
    }
  }
}