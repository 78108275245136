@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "tile";

.#{$object} {
  background-color: $color-white;
  align-self: center;
  height: auto;
  max-width: 100%;
  text-decoration: none;
  padding-left: rem-calc(20px);
  padding-right: rem-calc(20px);

  &.condensed {
    block-size: fit-content;
    display: block;
    vertical-align: top;
    position: relative;
  }

  &--primary {
    border: 1px solid $color-light;
  }

  &__header {
    font-family: $font-primary;    
    color: $color-primary !important; // important to override global theme font color;
    padding-top: rem-calc(30px);
    margin: rem-calc(0);
    font-size: rem-calc(24px);
    &.condensed {
      padding-top: rem-calc(20px);
      padding-right: rem-calc(30px);
      min-width: 100%;
      display: inline-block;
      &.textOnly {
        min-width: 100%;
        float: left;
        clear: left;
      }
    }
  }

  &__description > * {
    color: $color-medium !important; // important to override global theme font color;
    font-weight: $font-weight-normal;
  }

  &__description.condensed {
    padding-right: rem-calc(30px);
    min-width: 100%;
    width: 100%;
    display: inline-block;
    &.textOnly {
      width: 100%;
      float: left;
      clear: left;
    }
  }

  &__description.condensed > * {
    margin-top: rem-calc(3px);
    margin-bottom: rem-calc(3px);
  }

  &__image {
    width:100%;
  }

  &__image-wrapper {
    float: right;
    clear: right;
    max-width: 100%;
    margin-top: rem-calc($gutter-mobile);
    margin: 0;
  }

  &__image-wrapper.condensed {
    display:none;
  }

  &__image.condensed {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  &__image-wrapper-head {
    width: 100%;
    & .tile__image {
      float: right;
      position: relative;
    }
  }

  &--border-radius {
    border-radius: 6px;
  }

  &--border-radius-bottom {
    border-radius: 0 0 6px 6px;
  }

  &--cta:hover {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  &__condensed-action-arrow {
    padding: rem-calc(5px);
    height: rem-calc(28px);
    width: rem-calc(28px);

    position: absolute;
    right: rem-calc(15px);
    bottom: rem-calc(15px);

    text-align: center;
    font-size: rem-calc(34px);
    font-weight: 100;
    color: $color-dark;

    background-color: $color-secondary;
    border-radius: 50%;
  }

  @media (min-width: map-get($breakpoints, large)) {
    &.feature.condensed {
      width: calc(100% - rem-calc(40px) /*padding*/ - rem-calc(75px) /*half-image-size*/);
      margin-left: rem-calc(75px);
    }
    &__condensed-action-arrow {
      top: auto;
      top: calc(50% - rem-calc(20px));
    }

    &__image-wrapper.condensed {
      display: block;
      width: rem-calc(100px);
      height: rem-calc(100px);
      top: 50%;
      transform: translate(rem-calc(-20px), -50%);
      position: absolute;
      &.feature {
        width: rem-calc(150px);
        height: rem-calc(150px);
        left: rem-calc(-95px);
      }
    }

    &__image-wrapper-head {
      display:none;
    }
    &__description.condensed {
      float: right;
      clear: right;
      min-width: calc(100% - rem-calc(150px));
      width: calc(100% - rem-calc(150px));
      &.feature {
        min-width: calc(100% - rem-calc(95px));
        width: calc(100% - rem-calc(95px));
      }
    }

    &__header.condensed {
      float: right;
      clear: right;
      min-width: calc(100% - rem-calc(150px));
      &.feature {
        min-width: calc(100% - rem-calc(95px));
      }
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    &.feature.condensed {
      width: calc(100% - rem-calc(40px) /*padding*/ - rem-calc(100px) /*half-image-size*/);
      margin-left: rem-calc(100px);
    }
    &__image-wrapper.condensed.feature {
      width: rem-calc(200px);
      left: rem-calc(-100px);
    }
    &__description.condensed.feature {
      min-width: calc(100% - rem-calc(100px));
      width: calc(100% - rem-calc(100px));
    }
    &__header.condensed.feature {
      min-width: calc(100% - rem-calc(100px));
      max-width: calc(100% - rem-calc(100px));
      max-height: rem-calc(65px);
      overflow: hidden;
    }
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary !important; // important to override global theme font color;
    align-self: inherit;
    height: 100%;

    &__header {
      max-width: rem-calc(340px);
      font-size: rem-calc(32px);
      line-height: rem-calc(31px);
      &.condensed {
        font-size: rem-calc(24px);
      }
    }

    &__image {
      float: right;
      clear: right;
      margin-top: auto;
    }

    &__image-wrapper.condensed {
      float: left;
      clear: left;
    }

    &--padding {
      padding: rem-calc(40px) rem-calc(40px) rem-calc(0);
    }
  }
}
