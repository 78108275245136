$color-text-default: #666666;
$color-primary-mexico: var(--color-primary-mexico);
$color-primary: var(--color-primary);
$color-primary-light: var(--color-primary-light);
$color-primary-dark: var(--color-primary-dark);
$color-secondary: #ffdd55;
$color-secondary-light: #ffef93;
$color-tertiary: #003882;
$color-light: #e6e6e6;
$color-medium: #666666;
$color-dark: #333333;
$color-light-background: #f1f1f1;

$color-white: #ffffff;
$color-black: #000000;

$color-error: #c90303;

$color-site-message-info: #0054a6;
$color-alert-border: #e10000;
$color-alert-red: #fdcccc;

$color-proplanet-leaf: #00945f;
$color-proplanet-blue: #0655A5;


$text-background-white: rgba(255, 255, 255, 0.8);
$text-background-black:rgba(0, 0, 0, 0.8);
$text-background-blue: rgba(9, 64, 118, 0.8);
$text-background-green: rgba(13, 51, 31, 0.8);

$font-primary: "Manrope",
sans-serif;
$font-secondary: "Bicyclette",
sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;

$font-default-size:16px;

$breakpoints: (small: 0,
  medium-small: 640px,
  medium: 768px,
  large: 992px,
  xlarge: 1150px,
  xxlarge: 1440px,
);

$width-max: 1440px;
$width-max-content: 1320px;
$gutter-desktop: 60px;
$gutter-mobile: 20px;

$topBarHeightMobile: 60px;

$icomoon-font-family: "darticons" !default;
$icomoon-font-path: "../fonts" !default;

$darticon-largearrow: "\e930";
$darticon-download: "\e92f";
$dartmadeinusa: "\e92e";
$dartsourcereduced: "\e92a";
$dartrenewablecontent: "\e92b";
$dartrecycle: "\e92c";
$dartrecycledcontent: "\e92d";
$dartcommerciallycompostable: "\e943";
$dartpaper: "\e920";
$dartfiber: "\e921";
$dartps: "\e922";
$dartpm: "\e923";
$dartops: "\e924";
$darthips: "\e925";
$darteps: "\e926";
$dartpp: "\e927";
$dartrpet: "\e928";
$dartpete: "\e929";
$darticon-map: "\e91f";
$darticon-youtube: "\e91e";
$darticon-alert: "\e91d";
$darticon-print: "\e915";
$darticon-filter: "\e916";
$darticon-sort: "\e917";
$darticon-clock: "\e918";
$darticon-fax: "\e919";
$darticon-location: "\e91a";
$darticon-phone: "\e91b";
$darticon-play: "\e91c";
$darticon-viewlist: "\e912";
$darticon-cart: "\e913";
$darticon-email: "\e914";
$darticon-webpages: "\e900";
$darticon-twitter: "\e901";
$darticon-search: "\e902";
$darticon-region: "\e903";
$darticon-products: "\e904";
$darticon-plus: "\e905";
$darticon-pinterest: "\e906";
$darticon-pdf: "\e907";
$darticon-menu: "\e908";
$darticon-linkedin: "\e909";
$darticon-headersearch: "\e90a";
$darticon-facebook: "\e90b";
$darticon-close: "\e90c";
$darticon-checkmark: "\e90d";
$darticon-backtotop: "\e90e";
$darticon-article: "\e90f";
$darticon-arrow: "\e910";
$darticon-account: "\e911";
$darticon-PFASfree: "\e931";
$darticon-proPlanet_Leaf: "\e932";
