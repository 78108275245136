@use "../../sass/variables" as *;
@use "../../sass/mixins" as *;

$object: "article-header";

.#{$object} {
    position: relative;
    padding: rem-calc(40px) rem-calc($gutter-mobile);
    max-width: rem-calc($width-max);
    margin: rem-calc(0) auto;

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__wrapper {
        position: relative;

        &--with-image {
            max-height: rem-calc(525px);
            background-position: right top;
            background-repeat: no-repeat;
        }

        &--bg-white {
            background-color: $text-background-white;
        }
        &--bg-black {
            background-color: $text-background-black;
        }
        &--bg-blue {
            background-color: $text-background-blue;
        }
        &--bg-green {
            background-color: $text-background-green;
        }
    }

    &__published-date,
    &__updated-date {
        margin: rem-calc(0);
    }

    &__published-date {
        @include small-font;
    }

    &__updated-date {
        margin: rem-calc(10px) rem-calc(0) rem-calc(0) rem-calc(0);
        @include extra-small-font;
    }

    &__title {
        margin: rem-calc(30px) rem-calc(0) rem-calc(0) rem-calc(0);
        font-size: rem-calc(32px);
        line-height: rem-calc(31px);
    }

    &__categories {
        display: inline-block;
        list-style: none;
        margin: rem-calc(5px) rem-calc(0) rem-calc(0) rem-calc(0);
        padding: rem-calc(0);
        @include small-font;

        &__item {
            display: inline;
            text-transform: capitalize;

            &::after {
                content: "|";
                display: inline-block;
                margin: rem-calc(0) rem-calc(10px);
            }

            &:last-of-type {
                &::after {
                    content: "";
                }
            }
        }
    }

    &__description {
        margin: rem-calc(30px) rem-calc(0) rem-calc(0) rem-calc(0);

        * {
            @include small-font;
        }

        p {
            margin: rem-calc(0);
        }
    }

    &__cta {
        margin: rem-calc(30px) rem-calc(0) rem-calc(0) rem-calc(0);
        align-self: flex-start;
    }

    &__mobile-image {
        display: block;
    }

    &__mobile-image {
        width: 100%;
        height: auto;
    }

    @media (min-width: map-get($breakpoints, "large")) {
        padding: rem-calc(0) rem-calc($gutter-desktop);
        display: flex;
        align-items: center;
        flex: auto 1 1;

        &__wrapper {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }

        &__title {
            margin: rem-calc(45px) rem-calc(0) rem-calc(0) rem-calc(0);
            font-size: rem-calc(48px);
            line-height: rem-calc(46px);
        }

        &__description {
            margin: rem-calc(25px) rem-calc(0) rem-calc(0) rem-calc(0);

            * {
                font-size: rem-calc(18px);
                line-height: rem-calc(28px);
            }
        }

        &__cta {
            @include medium-font;
        }

        &__content {
            max-width: 52%;
            padding: 6.5% rem-calc(0);
        }
    }
}

.#{$object}.theme-tertiary{
    .#{$object} a{
        color: $color-white;
        // text-decoration: none;
        font-weight: normal;
        &:hover{
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }
    }
  }
