@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'page-header';

.#{$object} {
  background-color: $color-white;
  position: relative;

  &--secondary {
    background-color: $color-light;
  }

  &--tertiary {
    background-color: $color-primary;
  }

  &--hero {
    .#{$object}__content {
      min-height: auto;
    }
    .#{$object}__image {
      position: static;
      max-width: 100%;
      display: block;
      width:100%;
      object-fit:cover;
    }

    .#{$object}__content {
      width: 100%;
    }

    @media (min-width: map-get($breakpoints, medium)) {
      .#{$object}__content {
        min-height: rem-calc(645px);
      }
      .#{$object}__image {
        position: absolute;
        right: 0;
        top: 0;
      }
      .#{$object}__content {
        width: 50%;
      }
    }

    @media (min-width: map-get($breakpoints, xlarge)) {
      .#{$object}__content {
        width: 33%;
      }
    }
  }

  &--banner {
    .page-header__content {
      min-height: rem-calc(325px);
    }
  }

  & .btn--tertiary {
    background-color: $color-secondary;
    border: 2px solid $color-secondary;
    &:hover, &:focus {
      background-color: $color-secondary-light;
      border: 2px solid $color-secondary-light;
      color: $color-dark;
    }
  }

  &--bg-white {
    background-color: rgba($text-background-white,0.8);
  }
  &--bg-black {
    background-color: rgba($text-background-black,0.8);
  }
  &--bg-blue {
    background-color: rgba($text-background-blue,0.8);
  }
  &--bg-green {
    background-color: rgba($text-background-green,0.8);
  }

  &--bg-black,
  &--bg-blue,
  &--bg-green {
    .#{$object}__header--primary,
    .#{$object}__header--secondary {
      color: $color-white;
    }

    .#{$object}__description-rte-wrapper--primary,
    .#{$object}__description-rte-wrapper--secondary {
      color: $color-white;
      * {
        color: $color-white;
      }
    }
  }

  &--bg-white {
    .#{$object}__header--tertiary {
      color: $color-primary;
    }

    .#{$object}__description-rte-wrapper--tertiary {
      color: $color-medium;
      * {
        color: $color-medium;
      }
    }
  }

  &__inner {
    max-width: rem-calc($width-max);
    margin: rem-calc(0) auto;
    background-size: 0;
    background-position: 0;
    background-repeat: no-repeat;
    align-items: center;
  }

  &__image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    display: none;
  }

  &__video-container.video-container { //override Video component
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    max-width: 100%;
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: flex-end;
    align-content: center;
    &--hero {
      position: relative;
    }
  }

  &__content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: rem-calc($gutter-mobile);
    height: auto;
    padding: rem-calc(40px) rem-calc($gutter-mobile) rem-calc(55px);

    &__cta {
      max-width: -moz-fit-content;
      max-width: fit-content;
      margin-top: rem-calc(25px);
      flex: auto 0 0;
    }
  }

  &__header {
    font-size: rem-calc(28px);
    line-height: rem-calc(27px);
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    color: $color-primary;
    text-transform: uppercase;
    margin: rem-calc(0) rem-calc(0) rem-calc(7px) rem-calc(0);
  }

  &__header--secondary {
    color: $color-site-message-info;
  }

  &__header--tertiary {
    color: $color-white;
  }

  &__description-rte-wrapper {
    display: block;
    font-weight: $font-weight-medium;
    font-size: rem-calc(16px);
    line-height: rem-calc(22px);
    color: $color-medium;

    * {
      color: $color-medium;
      font-size: rem-calc(16px);
      line-height: rem-calc(22px);
    }
  }

  &__description-rte-wrapper--tertiary {
    color: $color-white;
    a,
    * {
      color: $color-white;
    }
  }

  &__description-rte-wrapper p {
    margin: rem-calc(0);
  }

  &__description-rte-wrapper-toggle {
    cursor: pointer;
    background-color: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    font-weight: $font-weight-medium;
    color: $color-dark;

    &-text {
      text-decoration: underline;
      display: flex;
      align-items: center;
      font-weight: $font-weight-bold;

      &:hover {
        text-decoration: none;
      }
    }
    &-icon {
      display: flex;
      align-items: center;
      margin-left: rem-calc(10px);
    }
  }

  @media (max-width: map-get($breakpoints, medium-small)) {
    &__video-container.video-container { //unoverride Video component
      position: relative;
    }
  }

  @media (min-width: map-get($breakpoints, medium)) {
    &__inner {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &--full {
        width: 100%;
        .#{$object}__content {
          width: 100%;
        }
      }
      &--left, &--none {
        justify-content: left;
      }
      &--right {
        justify-content: right;
      }
      &--center {
        justify-content: center;
      }
    }
  }

  @media (min-width: map-get($breakpoints, large)) {
    padding: rem-calc(0px) rem-calc($gutter-desktop);

    &--no-image-no-text-bg {
      .#{$object}__content {
        width: 75%;
      }
    }

    &__inner {
      background-size: auto;
      background-position: top right;
      background-repeat: no-repeat;
    }

    &__image {
      display: block;
      width:100%;
      object-fit:cover;
    }

    &__header {
      font-size: rem-calc(48px);
      line-height: rem-calc(46px);
      margin-bottom: rem-calc(10px);
    }

    &__description-rte-wrapper {
      font-size: rem-calc(18px);
      line-height: rem-calc(25px);
    }

    &__description-rte-wrapper>* {
      font-size: rem-calc(18px);
      line-height: rem-calc(25px);
    }
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    &--no-image {
      .#{$object}__content {
        width: 78%;
      }
    }

    &__content {
      width: 57%;
    }

    &__header {
      font-size: rem-calc(48px);
      line-height: rem-calc(46px);
      margin-bottom: rem-calc(10px);
    }

    &__description-rte-wrapper {
      font-size: rem-calc(18px);
      line-height: rem-calc(25px);
    }
  }
}

.#{$object}.theme-tertiary{
    .#{$object} a{
        color: $color-white;
        font-weight: normal;
        &:hover{
          font-weight: $font-weight-bold;
          text-decoration: underline;
        }
    }
  }