@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "simple-authored-tile";

.#{$object} {
  height: rem-calc(165px);
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &--primary {
    border: 1px solid $color-light;
  }

  &__heading {
    padding-top: rem-calc(20px);
    margin: rem-calc(0);
    font-size: rem-calc(16px);
    line-height: rem-calc(15px);
    color: $color-primary !important; //important to override global theme font color
    font-family: $font-secondary;
    text-transform: uppercase;
  }

  &__subheading {
    font-size: rem-calc(13px);
    line-height: rem-calc(13px);
    text-transform: uppercase;
    color: $color-primary !important; //important to override global theme font color
    margin-block-start: 0.5em;
  }

  //start wizardry to force the image to shrink to the size of the div
  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__image {
    aspect-ratio: auto 2 / 1;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    max-inline-size: 100%;
    block-size: auto;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  //end wizardry

  &--padding-side {
    padding-left: rem-calc(20px);
    padding-right: rem-calc(20px);
  }

  &--border-radius {
    border-radius: 6px;
  }

  &--border-radius-bottom {
    border-radius: 0 0 6px 6px;
  }

  &--cta:hover {
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  @media (min-width: map-get($breakpoints, medium)) {
    height: rem-calc(268px);

    &__heading {
      font-size: rem-calc(30px);
      line-height: rem-calc(29px);
    }
    &__subheading {
      font-size: rem-calc(21px);
      line-height: rem-calc(20px);
    }

    &--padding {
      padding: rem-calc(40px) rem-calc(40px) rem-calc(0);
    }
  }
}
