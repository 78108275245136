@use "../../sass/variables" as *;
@use "../../sass/mixins" as *;

$object: "site-messages";

.#{$object} {    

    &__message {
        color: $color-dark;
        padding: rem-calc(0) rem-calc($gutter-mobile);
        border: 1px solid;

        &--informational {
            border-color: $color-site-message-info;
            background-color: rgba($color-site-message-info, 0.15);
        }

        &--error {
            background-color: $color-alert-red;
            border-color: $color-alert-border;
        }

        &--hidden {
            display: none;
        }

        &__icon {
            font-size: rem-calc(20px);
            height: min-content;
            margin-right: rem-calc(10px);
        }

        &__inner {
            display: flex;
            margin: auto;
            max-width: rem-calc($width-max);
            padding: rem-calc(10px) rem-calc(0px);
        }

        &__inner-content {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .icon-notification {
                font-size: rem-calc(18px);
                color: black;
            }
        }

        &__text {
            margin: rem-calc(0);
            @include extra-small-font;

            > p {
                @include extra-small-font;
                margin: rem-calc(0);
                display: inline;
                color: $color-dark;
            }
        }

        &__button {
            min-width: rem-calc(25px);
            height: rem-calc(25px);
            background: transparent;
            border: none;
            padding: rem-calc(0);
            cursor: pointer;
            margin-left: rem-calc(20px);
        }
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        &__message {
            padding: rem-calc(0) rem-calc($gutter-desktop);

            &__inner {
                align-items: center;
                padding: rem-calc($gutter-mobile) rem-calc(0);
            }

            &__inner-content {
                align-items: center;
            }

            &__text {
                @include small-font;

                > p {
                    @include small-font;
                }
            }

            &__button {
                min-width: rem-calc(30px);
                height: rem-calc(30px);
            }
        }
    }

    // When printing from Saved Products page
    @media print {
        display: none;
    }
}
