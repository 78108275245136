@import "../../../sass/variables";
@import "../../../sass/mixins";

$object: "video-banner";

.#{$object} {
  padding: rem-calc($gutter-mobile);
  margin: rem-calc(0) auto;

  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    &.no-image {
      background-color: rgba($color: $color-black, $alpha: 0.65);
      padding: rem-calc($gutter-desktop) rem-calc($gutter-mobile);
    }
  }

  &__image {
    filter: brightness(50%);
    max-width: 100%;
    width: 100%;
    border-radius: 6px;
    object-fit: cover;
    object-position: center center;
  }

  &__content-wrapper {
    max-width: rem-calc(246px);
    text-align: center;

    &.with-image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &.no-image {
      margin: rem-calc(0px) auto;
      position: relative;
    }
  }

  &__header {
    font-size: rem-calc(24px);
    line-height: rem-calc(23px);
    color: $color-white;
    margin: rem-calc(0px) rem-calc(0px) rem-calc(10px);
  }

  @media (min-width: map-get($breakpoints, medium)) {
    max-width: rem-calc($width-max);
    padding: rem-calc($gutter-desktop);

    &__image-wrapper {
      &.no-image {
        padding: rem-calc($gutter-desktop);
      }
    }

    &__content-wrapper {
      max-width: rem-calc(642px);
    }

    &__header {
      font-size: rem-calc(42px);
      line-height: rem-calc(41px);
    }

    &__description-wrapper {
      & * {
        color: $color-white;
        margin: rem-calc(0px) rem-calc(0px) rem-calc(40px);
      }
    }
  }
}