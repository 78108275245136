@import "../../sass/variables";
@import "../../sass/mixins";

$object: "saved-products-card";

.#{$object} {
  &__card-outer {
    border: 1px solid $color-light;
    border-radius: 6px;
    padding: rem-calc(10px);
    position: relative;
  }

  &__card-inner {
    display: flex;
    width: 100%;
  }

  &__add-product-cta {
    left: rem-calc(55px);
    position: absolute;
    top: rem-calc(-10px);
  }

  &__banners {
    position: absolute;
    top: rem-calc(45px);
    left: rem-calc(0px);
    z-index: 3;
  }

  &__banner {
    color: $color-white;
    border-radius: 0px 2px 2px 0px;
    font-size: rem-calc(11px);
    font-weight: $font-weight-extra-bold;
    line-height: rem-calc(15px);
    margin: rem-calc(5px) rem-calc(0px) rem-calc(0px);
    max-width: max-content;
    padding: rem-calc(3px) rem-calc(10px) rem-calc(3px) rem-calc(15px);

    &:first-child {
      margin: 0px;
    }

    &.secondary {
      background-color: $color-medium;
    }

    &.primary {
      background-color: $color-primary;
    }
  }

  // Product Image
  &__product-image-wrapper {
    margin-right: rem-calc(10px);

    &-img-mobile {
      min-width: rem-calc(140px);
      width: rem-calc(140px);
    }
  }

  &__product-image-img-mobile {
    aspect-ratio: 1;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  &__product-discontinued {
    background-color: $color-medium;
    color: $color-white;
    font-size: rem-calc(12px);
    font-weight: $font-weight-medium;
    line-height: rem-calc(16px);
    margin: rem-calc(0px);
    padding: rem-calc(5px);
    text-align: center;
  }

  // Product Info
  &__product-name {
    color: $color-dark;
    margin: rem-calc(0px) rem-calc(0px) rem-calc(10px);
  }

  &__product-text,
  &__product-text>* {
    font-size: rem-calc(12px);
    font-weight: $font-weight-medium;
    line-height: rem-calc(18px);
    margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);

    &:first-of-type {
      margin-top: rem-calc(0px);
    }

    &.sku-info {
      color: $color-medium;
    }

    &.description-count {
      color: $color-dark;

      & * {
        font-weight: $font-weight-semi-bold;
      }
    }

    &.alert * {
      color: $color-alert-border;
    }
  }

  // Vertical Separator "|"
  &__vertical-separator {
    margin-inline: rem-calc(7px);
  }

  &__view-details-cta {
    margin-top: rem-calc(10px);
    padding: rem-calc(0px) rem-calc(10px);
    width: 100%;
  }

  // Load More Wrapper
  &__load-more-wrapper {
    margin: auto;
    max-width: max-content;
  }

  &__product-features-wrapper {
    align-self: flex-end;
    margin: rem-calc(55px) rem-calc(0px) rem-calc(0px) auto;
    max-width: rem-calc(230px);
    width: 100%;
  }

  &__features-label {
    color: $color-dark;
    font-size: rem-calc(14px);
    font-weight: $font-weight-extra-bold;
    line-height: rem-calc(18px);
  }

  &__features-list {
    list-style-type: none;
    list-style-position: inside;
    margin: rem-calc(5px) rem-calc(0px) rem-calc(10px);
    padding-inline-start: rem-calc(0px);
  }

  &__checkmark-icon {
    color: $color-primary;
    margin-right: rem-calc(10px);
  }

  &__feature {
    color: $color-dark;
    display: inline-flex;
    font-size: rem-calc(14px);
    font-weight: $font-weight-semi-bold;
    line-height: rem-calc(16px);
    margin-top: rem-calc(10px);
    width: 100%;
  }

  &__feature:first-of-type {
    margin-top: rem-calc(0px);
  }

  // Desktop Breakpoint
  @media (min-width: map-get($breakpoints, medium)) {
    &__card-outer {
      padding: rem-calc(20px);
    }

    &__add-product-cta {
      left: auto;
      right: rem-calc(0px);
      top: rem-calc(0px);
      width: 100%;
    }

    &__banners {
      top: 20px;
    }

    &__banner {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
      padding: rem-calc(5px) rem-capc(15px) rem-calc(5px) rem-calc(20px);
    }

    &__product-image-wrapper {
      margin-right: rem-calc(30px);

      &-img-desktop {
        min-width: rem-calc(200px);
        width: auto;
      }
    }

    &__product-image-img-desktop {
      max-width: rem-calc(260px);
    }

    &__product-discontinued {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      padding: rem-calc(10px);
    }

    &__product-name {
      margin-bottom: rem-calc(20px);
    }

    &__product-info-wrapper {
      margin-right: rem-calc(30px);
      max-width: rem-calc(700px);
    }

    &__product-text,
    &__product-text>* {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      margin-top: rem-calc(20px);

      &.alert {
        margin-top: rem-calc(20px);
      }
    }
  }

  // Print Formatting
  @media print {
    &__card-outer {
      padding: rem-calc(20px);
    }

    &__add-product-cta {
      left: auto;
      right: rem-calc(0px);
      top: rem-calc(0px);
      width: 100%;
    }

    &__banners {
      top: 20px;
    }

    &__banner {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
      padding: rem-calc(5px) rem-capc(15px) rem-calc(5px) rem-calc(20px);
    }

    &__product-image-wrapper {
      margin-right: rem-calc(30px);
      min-width: rem-calc(200px);
      width: auto;
    }

    &__product-image {
      max-width: rem-calc(260px);
    }

    &__product-discontinued {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      padding: rem-calc(10px);
    }

    &__product-name {
      margin-bottom: rem-calc(20px);
    }

    &__product-info-wrapper {
      margin-right: rem-calc(30px);
      max-width: rem-calc(700px);
    }

    &__product-text,
    &__product-text>* {
      font-size: rem-calc(14px);
      line-height: rem-calc(19px);
      margin-top: rem-calc(20px);

      &.alert {
        margin-top: rem-calc(20px);
      }
    }
  }
}