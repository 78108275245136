@use "../../../sass/variables"as *;
@use "../../../sass/mixins"as *;

$object: "content-tabs";

.#{$object} {

	font-family: $font-primary;
	font-size: rem-calc(16px);
	padding: rem-calc($gutter-mobile) rem-calc(0px);

	// Wrapper
	&__wrapper {
		display: flex;
		justify-content: center;
		gap: rem-calc(3px);
		max-width: rem-calc($width-max);
		margin-inline: auto;
		padding: rem-calc(0px) rem-calc($gutter-mobile);
	}

	// Content Tab Item
	&__item {
		align-items: center;
		background-color: $color-light;
		border: none;
		color: $color-dark;
		cursor: pointer;
		display: flex;
		font-weight: $font-weight-bold;
		justify-content: center;
		line-height: rem-calc(22px);
		min-height: rem-calc(30px);
		padding: rem-calc($gutter-mobile);
		text-align: center;
		text-decoration: none;
		width: 100%;

		// Active & Hover states
		&.active,
		&:hover {
			color: $color-white;
			background-color: $color-primary;
		}
	}

	// Desktop Breakpoint
	@media (min-width: map-get($breakpoints, medium)) {
		&__wrapper {
			padding: rem-calc(0px) rem-calc($gutter-desktop);
		}
	}
}