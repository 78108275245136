@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "curated-list-toolbar";

.#{$object} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: rem-calc($gutter-mobile) rem-calc(0);
    margin: rem-calc(0) auto;
    max-width: rem-calc($width-max);

    &__sidebar {
        box-sizing: border-box;
    }

    &__content {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__mobile-pills {
        flex: 100% 1 0;
        margin-top: rem-calc(40px);
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        flex-wrap: nowrap;

        &__sidebar {
            flex: 25% 0 0;
            margin: rem-calc(0) rem-calc(45px) rem-calc(0) rem-calc(0);
            padding: rem-calc($gutter-mobile) rem-calc(0);
        }

        &__content {
            flex: auto 1 1;
            margin-right: rem-calc(0);

            &__sort {
                flex-basis: auto;
                flex-grow: 0;
                flex-shrink: 0;
                margin-left: auto;
            }

            &__pills {
                margin-right: rem-calc(45px);
            }
        }
    }
}
