@use "../../sass/variables"as *;
@use "../../sass/mixins"as *;

$object: "anchor-link";

.#{$object} {
    height: 0;
    visibility: hidden;
    pointer-events: none;

    &-preview {
        padding: rem-calc(20px) rem-calc(0);
        text-align: center;

        &__title {
            margin: rem-calc(0) rem-calc(0) rem-calc(20px) rem-calc(0);
            font-family: $font-primary;            

            &--invalid {
                color: $color-error;
            }
        }
    }
}