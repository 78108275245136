@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'navigation-product-tile';

.#{$object} {
  border: 1px solid $color-light;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: rem-calc(260px);
  height: auto;
  min-height: rem-calc(240px);
  max-height: rem-calc(340px);
  position: relative;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 3px 7px 1px rgba(0, 0, 0, 0.1);
  }

  &__add {
    position: absolute;
    top: rem-calc(10px);
    right: rem-calc(10px);
    z-index: 16;

    > .add-product {
      position: relative;
      top: unset;
      right: unset;
    }
  }

  &__img {
    margin-bottom: rem-calc(22px);
    max-width: rem-calc(163px);
    max-height: rem-calc(163px);

    &-wrapper {
      flex: auto 2 0;
      flex-direction: column;
      max-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
     }
  }

  &__product-title {
    text-decoration: underline;
    padding: rem-calc(0) rem-calc(15px);
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
    font-weight: $font-weight-extra-bold;

    &:hover {
      text-decoration: none;
    }
  }

  // Banners Container
  &__banners {
      position: absolute;
      top: rem-calc(0px);
      padding: rem-calc(15px);
      left: rem-calc(0px);
      z-index: 3;
      width: 100%;
      height: 100%;
      pointer-events: none;
  }

  // Individual Banner
  &__banner {
      color: $color-white;
      border-radius: 0px 2px 2px 0px;
      font-size: rem-calc(11px);
      font-weight: $font-weight-extra-bold;
      line-height: rem-calc(15px);
      margin: rem-calc(5px) rem-calc(0px) rem-calc(0px);
      max-width: max-content;
      padding: rem-calc(3px) rem-calc(10px) rem-calc(3px) rem-calc(15px);
      pointer-events: none;

      // Seconday - Gray Color
      &.secondary {
          background-color: $color-medium;
      }

      &.bottom {
        position: absolute;
        bottom: 30px;
      }

      // Primary - Blue Color
      &.primary {
          background-color: $color-primary;
      }

      // Icon
      &.icon {
          padding: 0 rem-calc(10px);
      }
      &.icon > img{
          width: rem-calc(28px);
      }

      @media (min-width: map-get($breakpoints, medium)) {
        font-size: rem-calc(14px);
        line-height: rem-calc(19px);
        margin: rem-calc(10px) rem-calc(0px) rem-calc(0px);
        padding: rem-calc(5px) rem-calc(15px) rem-calc(5px) rem-calc(20px);

        &.bottom {
          position: absolute;
          bottom: 30px;
        }

        // Icon
        &.icon > img{
            width: rem-calc(50px);
        }
      }
  }

  &__banner:first-child {
      margin: rem-calc(0px);
  }

  @media (min-width: map-get($breakpoints, large)) {
    &__img {
      margin-bottom: rem-calc(20px);
    }

    &__product-title {
      padding: rem-calc(0) rem-calc(20px);
      font-size: rem-calc(16px);
      line-height: rem-calc(20px);
      margin-bottom: rem-calc(40px);
    }
  }

  @media (min-width: map-get($breakpoints, "xlarge")) {
    &__add {
      > .add-product {
        &:hover {
          .add-product__add-link {
            padding: rem-calc(0) rem-calc(25px);
          }
        }
      }
    }
  }
}