@import "../../sass/variables";
@import "../../sass/mixins";

$object: 'app-wrapper';

.#{$object} {
  margin: rem-calc(0) auto;

  @media (min-width: map-get($breakpoints, medium)) {}

  @media (min-width: map-get($breakpoints, large)) {}
}