@use "../../../sass/variables" as *;
@use "../../../sass/mixins" as *;

$object: "filter-count";

.#{$object} {
    display: flex;
    align-items: center;
    color: $color-dark;
    height: rem-calc(50px);
    text-transform: capitalize;

    &__mobile-button {
        width: rem-calc(30px);
        height: rem-calc(30px);
        background: transparent;
        border: none;
        padding: rem-calc(0);
        text-align: center;
        color: $color-dark;
        font-size: rem-calc(20px);
        cursor: pointer;
        margin-right: rem-calc(7px);
    }

    &__value {
        @include small-font;
    }

    @media (min-width: map-get($breakpoints, "medium")) {
        &__value {
            @include medium-font;
        }
    }
}
