@import "../../../../sass/variables";
@import "../../../../sass/mixins";

$object: "tile-container";

.#{$object} {
  margin: rem-calc(0) auto;

  &__wrapper {
    padding: rem-calc($gutter-mobile);
  }

  &__tiles {
    margin: rem-calc(0) auto;
    display: grid;
    grid-auto-rows: auto;
    column-gap: rem-calc(20px);
    row-gap: rem-calc(20px);
    grid-template-columns: repeat(1, minmax(0, 1fr));
    justify-content: center;
  }

  &__tiles.condensed {
    grid-auto-rows: auto;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    max-width: rem-calc(500px);
    &.two_across {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      max-width: rem-calc(500px);
    }
  }

  &__cta-wrapper {
    text-align: center;
    margin: rem-calc(40px) rem-calc(0);
  }

  @media (min-width: map-get($breakpoints, xlarge)) {
    &__tiles.condensed {
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      max-width: 100%;
      &.two_across {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-width: 66%;
      }
    }
  }

  @media (min-width: map-get($breakpoints, medium)) {
    color: $color-primary;

    &__wrapper {
      padding: rem-calc($gutter-desktop);
      max-width: rem-calc($width-max-content);
      margin: rem-calc(0) auto;
    }

    &__header {
      display: flex;
      align-items: center;
    }

    &__cta-wrapper {
      margin: rem-calc(30px) rem-calc(0px) rem-calc(30px) auto;
      padding: rem-calc(0px) rem-calc(0px);
    }

    &__cta {
      margin-left: rem-calc(5px);
    }

    &__tiles {
      grid-auto-rows: 1fr;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      max-width: 100%;
      &.two_across {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-width: 66%;
      }
    }
  }
}
