@import "../../sass/variables";
@import "../../sass/mixins";

$object: "footer-link-group";

.#{$object} {
  &__header {
    padding: rem-calc(0);
    width: 100%;
    background-color: transparent;
    border: 0;
    font-size: rem-calc(18px);
    line-height: rem-calc(18px);
    color: inherit;
    font-family: $font-secondary;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding-top: rem-calc(25px);
    padding-bottom: rem-calc(25px);
    display: flex;
    align-items: center;
    justify-content: space-between;


      @media (min-width: map-get($breakpoints, large)) {
        &--mobile{
            display:none;
        }
        &--desktop{
            display:block;
        }
      }
      @media (max-width: map-get($breakpoints, large)) {
        &--mobile{
            display:flex;
        }
        &--desktop{
            display:none;
        }
      }

  }

  &__header-text {
    display: block;
    text-align: left;
    margin: 0px;
    font-family: "Bicyclette", sans-serif;
    font-size: 1.125rem;
    color: $color-white;
  }

  &__header-icon {
    margin-left: rem-calc(20px);
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: inherit;
  }

  &__nav-list {
    list-style-type: none;
    margin: rem-calc(0);
    padding: rem-calc(0);
    display: block;
  }

  &__nav-list--display-toggle {
    display: none;
  }

  &__nav-list-item {
    width: 100%;
    padding-left: rem-calc(5px);
  }

  &__nav-list-item-link {
    font-family: $font-primary;
    font-weight: $font-weight-medium;
    font-size: rem-calc(14px);
    line-height: rem-calc(35px);
    color: inherit;
  }

  &__nav-list-item-login {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
  }

  &__nav-list-item-link-icon {
    font-size: rem-calc(18px);
    margin-right: rem-calc(8px);
  }
  @media (min-width: map-get($breakpoints, medium)) {

  }
  @media (min-width: map-get($breakpoints, medium)) {}

  @media (min-width: map-get($breakpoints, large)) {
    &__header {
      text-align: left;
      padding-top: rem-calc(0px);
      padding-bottom: rem-calc(10px);
    }

    &__header-icon {
      display: none;
    }

    &__nav-list--display-toggle {
      display: block;
    }

    &__nav-list-login {
      margin-top: rem-calc(50px);
    }

    &__nav-list-item {
      padding-left: rem-calc(0);
      padding-bottom: rem-calc(0);
    }

    &__nav-list-item-link {
      font-size: rem-calc(16px);
      line-height: rem-calc(39px);
    }
  }
}