@use "../../../../../sass/variables" as *;
@use "../../../../../sass/mixins" as *;

$primaryObject: "product-detail-product-tile";
$secondaryObject: "product-detail-anchor-tile";

.#{$primaryObject} {
    position: relative;
    box-sizing: border-box;
    display: inline-flex;
    flex-direction: column;
    background-color: $color-white;
    border-radius: rem-calc(6px);
    border: rem-calc(1px) solid $color-light-background;
    width: 100%;
    height: 100%;
    min-height: rem-calc(190px);
    padding: rem-calc(10px);
    text-decoration: none;

    .add-product {
        position: absolute;
        top: rem-calc(10px);
        right: rem-calc(10px);
    }

    &__image {
        max-width: 100%;

        &-wrapper {
            flex: auto 0 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }
    }

    &__name {
        @include extra-small-font;
        margin: rem-calc(10px) 0 0;
        color: $color-dark;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    @media (min-width: map-get($breakpoints, "xlarge")) {
        padding: rem-calc(20px);

        .add-product {
            top: rem-calc(20px);
            right: rem-calc(20px);
        }

        &__name {
            font-size: rem-calc(18px);
            line-height: rem-calc(23px);
        }
    }
}

.#{$secondaryObject} {
    @media (min-width: map-get($breakpoints, "xlarge")) {
        &:hover {
            box-shadow: 0 rem-calc(4px) rem-calc(10px) rem-calc(2px) rgba(0, 0, 0, 0.1);
            cursor: pointer;
        }
    }
}
